import { useRecoilState } from 'recoil';

import {
  NoAdModalState,
  RVFloatingState,
  RVInfoListState,
  blockedCampaignListState,
  bridgePageInfoState,
  campaignListState,
  cashNoteState,
  choiceOfferCampaignState,
  completedRcpcListState,
  cpmCampaignListState,
  csState,
  currencyState,
  customState,
  installedCampaignListState,
  is501ErrorState,
  isLandscapeState,
  isNewInAppBrowserState,
  isTestState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
  selectedCampaignState,
  selectedFilterOptionState,
  selectedTabCampaignState,
  selectedTabState,
  tabListState,
  temporalBlockedCampaignListValue,
  totalOfferwallRewardQuantityState,
  totalRewardState,
} from 'atoms/campaignState';

/**
 * @returns {{
 *   state: {
 *     [K in keyof typeof obj]: ReturnType<typeof useRecoilState>[0]
 *   },
 *   setState: {
 *     [K in keyof typeof obj as `set${Capitalize<string & K>}`]: ReturnType<typeof useRecoilState>[1]
 *   }
 * }}
 */

export const useCustomRecoilState = () => {
  const tabList = useRecoilState(tabListState);
  const selectedFilterOption = useRecoilState(selectedFilterOptionState);
  const bridgePageInfo = useRecoilState(bridgePageInfoState);
  const totalOfferwallRewardQuantity = useRecoilState(totalOfferwallRewardQuantityState);

  const selectedTab = useRecoilState(selectedTabState);
  const selectedCampaign = useRecoilState(selectedCampaignState);
  const campaignList = useRecoilState(campaignListState);
  const cpmCampaignList = useRecoilState(cpmCampaignListState);
  const choiceOfferCampaign = useRecoilState(choiceOfferCampaignState);
  const selectedTabCampaigns = useRecoilState(selectedTabCampaignState);

  const csInfo = useRecoilState(csState);

  const rvFloating = useRecoilState(RVFloatingState);
  const rvInfoList = useRecoilState(RVInfoListState);

  const blockedCampaigns = useRecoilState(blockedCampaignListState);
  const temporalBlockedCampaigns = useRecoilState(temporalBlockedCampaignListValue);
  const completedRcpcList = useRecoilState(completedRcpcListState);
  const installedCampaignList = useRecoilState(installedCampaignListState);

  const currency = useRecoilState(currencyState);
  const parameter = useRecoilState(parameterState);
  const custom = useRecoilState(customState);
  const mediaKey = useRecoilState(mediaKeyState);
  const isTest = useRecoilState(isTestState);
  const platform = useRecoilState(platformState);
  const totalReward = useRecoilState(totalRewardState);

  const isCashNote = useRecoilState(cashNoteState);
  const isNewInAppBrowser = useRecoilState(isNewInAppBrowserState);
  const isViewMode = useRecoilState(isViewModeState);
  const isLandscape = useRecoilState(isLandscapeState);
  const isNoAd = useRecoilState(NoAdModalState);
  const is501Error = useRecoilState(is501ErrorState);

  const obj = {
    totalOfferwallRewardQuantity,
    bridgePageInfo,
    campaignList,
    selectedCampaign,
    cpmCampaignList,
    currency,
    tabList,
    selectedTab,
    choiceOfferCampaign,
    selectedTabCampaigns,
    csInfo,
    rvFloating,
    rvInfoList,
    blockedCampaigns,
    completedRcpcList,
    parameter,
    custom,
    mediaKey,
    isTest,
    platform,
    totalReward,
    isCashNote,
    isNewInAppBrowser,
    isViewMode,
    isLandscape,
    isNoAd,
    is501Error,
    temporalBlockedCampaigns,
    selectedFilterOption,
    installedCampaignList,
  };

  const state = Object.fromEntries(Object.entries(obj).map(([key, [val]]) => [key, val]));
  const setState = Object.fromEntries(
    Object.entries(obj).map(([key, [_, setState]]) => [
      `set${key.charAt(0).toUpperCase() + key.slice(1)}`,
      setState,
    ])
  );

  return {
    state,
    setState,
  };
};
