import { Suspense } from 'react';

import { CampaignListLandscape } from './campaign-list-landscape';
import { TabController } from './tab-controller';

import { Header } from '../default';

export function MainPage() {
  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header />
      <div className="w-full h-full flex flex-col relative">
        <Suspense>
          <TabController>
            <Suspense>
              <CampaignListLandscape />
            </Suspense>
          </TabController>
        </Suspense>
        {/* <ChoiceOfferPopup /> */}
      </div>
    </div>
  );
}

export default MainPage;
