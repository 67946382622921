import { useErrorModal } from 'context';
import { useCustomRecoilState } from 'hooks';

export const Modal = () => {
  const {
    state: { custom },
  } = useCustomRecoilState();
  const {
    modalContent: {
      title = '알림',
      message = '',
      onCancel,
      cancelButtonText,
      onConfirm,
      confirmButtonText = '확인',
    },
  } = useErrorModal();

  return (
    <div
      className={`w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute bottom-0 left-0 overflow-hidden px-[30px] flex justify-center items-center`}
    >
      <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
        <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center font-semibold">
          {title}
        </div>
        <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex justify-center items-center">
          {message}
        </div>
        <div className="flex gap-[10px] font-semibold">
          {onCancel && (
            <button
              className="flex justify-center items-center w-full h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
              onClick={onCancel}
            >
              {cancelButtonText}
            </button>
          )}
          <button
            style={{
              backgroundColor: custom.primaryColor,
            }}
            onClick={onConfirm}
            className={`flex justify-center items-center w-full h-[46px] rounded-[8px] text-white`}
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
