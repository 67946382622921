import { useState } from 'react';

import { useCustomRecoilState, useSDKInterface } from 'hooks';

export const useModal = () => {
  const [modalContent, setModalContent] = useState(null);
  const { closeNewInAppBrowser, closeOfferwall } = useSDKInterface();

  const {
    state: { isViewMode, isNewInAppBrowser },
  } = useCustomRecoilState();

  const closeModal = () => {
    setModalContent(null);
  };

  const openModal = (errorMsg, cb) => {
    setModalContent({
      message: errorMsg,
      confirmButtonText: '닫기',
      onConfirm: () => {
        if (cb && typeof cb === 'function') {
          return cb();
        }
        if (!window) return;

        if (isViewMode) {
          return closeModal();
        }

        if (isNewInAppBrowser) {
          return closeNewInAppBrowser();
        }
        closeOfferwall();
      },
    });
  };

  const openCustomModal = (content) => {
    setModalContent(content);
  };

  return { modalContent, openModal, openCustomModal, closeModal };
};

export default useModal;
