import { Suspense } from 'react';

import { useQueryParams } from 'hooks';

import { CampaignList } from './campaign-list';
import { Header } from './header';
import { TabController } from './tab-controller';
import { UnauthorizedMessage } from './un-authorized-message';

export function MainPage() {
  const { isAgreed } = useQueryParams();
  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header />
      <div className="w-full h-full flex flex-col relative">
        <Suspense>
          <TabController>
            {!isAgreed ? (
              <UnauthorizedMessage />
            ) : (
              <Suspense>
                <CampaignList />
              </Suspense>
            )}
          </TabController>
        </Suspense>
      </div>
    </div>
  );
}

export default MainPage;
