import { MAIN_TAB_TYPES } from 'constants';

import { ChoiceOfferPopupWrapper } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

export const FixedChoiceOffer = () => {
  const { handleClickCampaign } = useClickCampaign();
  const {
    state: {
      choiceOfferCampaign,
      custom,
      currency,
      selectedTab: { selectedTabId },
    },
  } = useCustomRecoilState();

  if (!choiceOfferCampaign) return null;
  if (selectedTabId !== MAIN_TAB_TYPES['참여적립']) return null;

  return (
    <div
      className="flex left-0 p-[15px] absolute w-full shadow-card bg-white items-center justify-between z-20"
      role="presentation"
      onClick={(e) => {
        handleClickCampaign(choiceOfferCampaign);
      }}
    >
      <div className="flex gap-[8px]">
        <img
          src={choiceOfferCampaign?.IconImgURL || ''}
          alt="초이스오퍼 아이콘 이미지"
          className="w-[40px] h-[40px] rounded-[6px] relative top-[2px] left-[2px]"
        />
        <ChoiceOfferPopupWrapper size="small" />

        <div className="flex flex-col pl-8 justify-center">
          <span className="text-[14px] text-[#212529] leading-[18.2px]">
            {choiceOfferCampaign?.FeedTitle || ''}
          </span>
          <span className="text-[13px] text-[#808C99] leading-[15.6px]">
            {choiceOfferCampaign?.FeedDesc || ''}
          </span>
        </div>
      </div>
      <div
        style={{
          backgroundColor: custom.primaryColor,
        }}
        className={`h-[28px] py-[4px] px-[11px] rounded-[6px] text-[14px] text-white leading-[19.6px] max-w-[100px] whitespace-pre truncate `}
      >
        {choiceOfferCampaign?.RewardDetail?.Quantity
          ? `${numberWithCommas(choiceOfferCampaign?.RewardDetail?.Quantity)} ${currency}`
          : typeof choiceOfferCampaign?.Reward === 'string'
          ? `${numberWithCommas(choiceOfferCampaign?.Reward)}`
          : `${choiceOfferCampaign?.Reward || 0} ${currency}`}
      </div>
    </div>
  );
};
