import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Offerwall, TestOfferwall } from 'pages';
import { BridgePage, BridgeTestPage } from 'pages/bridge-page';

import { EventListenerWrapper } from './event-listener-wrapper';
import { NotFoundPage } from './pages';
import { CSPage, CSRequestForm } from './pages/cs-page';
import { DetailPage } from './pages/detail-page';
import { QueryParamWrapper } from './query-param-wrapper';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<QueryParamWrapper />}>
          <Route element={<EventListenerWrapper />}>
            <Route path="/SDK/list" element={<Offerwall />} />
            <Route path="/SDK/detail" element={<DetailPage />} />

            <Route path="/bridge" element={<BridgePage />} />
            <Route path="/testBridge" element={<BridgeTestPage />} />

            <Route path="/cs" element={<CSPage />} />
            <Route path="/cs-request" element={<CSRequestForm />} />

            <Route path="/testPage" element={<TestOfferwall />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
