import { useCustomRecoilState } from 'hooks';

export const SubTabSection = () => {
  const {
    state: {
      campaignList,
      selectedTab: { selectedTabId, selectedSubTabData },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  const selectedSubTabInfo =
    campaignList?.find((tab) => tab.TypeNo === selectedTabId)?.SubTabInfo || [];

  if (selectedSubTabInfo?.length === 0) return null;
  return (
    <div className="flex gap-8 px-16 py-8 text-13 whitespace-nowrap w-full overflow-x-auto">
      {selectedSubTabInfo?.map((subTab) => (
        <div
          className={`py-8 px-10 rounded-[6px] ${
            subTab.TypeNo === selectedSubTabData[selectedTabId]
              ? 'text-white bg-zigzagGray-900 font-semibold'
              : 'text-zigzagGray-400 border border-zigzagGray-150 font-medium bg-white'
          }`}
          key={subTab.TypeNo}
          role="presentation"
          onClick={() => {
            setSelectedTab((prev) => {
              return {
                ...prev,
                selectedSubTabData: {
                  ...prev.selectedSubTabData,
                  [prev.selectedTabId]: subTab.TypeNo,
                },
              };
            });
          }}
        >
          {subTab.Name?.replace(/#/g, '')}
        </div>
      ))}
    </div>
  );
};
