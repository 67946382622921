import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();

  function parseQueryString(query) {
    const params = {};
    // 쿼리 파라미터 추가
    query.forEach((value, key) => {
      params[key] = value;
    });

    if (Object.keys(params)?.length === 0) return null;
    return params;
  }

  const queryParams = new URLSearchParams(location.search + location.hash);

  return {
    queryParams,
    PID: queryParams.get('PID'),
    isLocalhost: queryParams.get('env') === 'localhost',
    isTestState: queryParams.get('isTest'),
    appKey: queryParams.get('app_key'),
    usn: queryParams.get('usn'),
    adid: queryParams.get('adid'),
    idfa: queryParams.get('idfa'),
    auth: queryParams.get('auth'),
    params: parseQueryString(queryParams),
    platform: queryParams.get('platform'),
    mediakey: queryParams.get('mediakey'),
    primaryColor: queryParams.get('primaryColor'),
    startTabIndex: queryParams.get('startTabIndex'),
    offerwallTitle: queryParams.get('offerwallTitle'),
    isAgreed: queryParams.get('agreement') === 'true',
    isViewType: queryParams.get('isViewType') === 'true',
    isLandScape: queryParams.get('isLandscape') === 'true',
    isNewInAppBrowser: queryParams.get('isNewInAppBrowser') === 'true',
    isRewardVideoIntegrated: queryParams.get('isRewardVideoIntegrated') === 'true',
  };
};
