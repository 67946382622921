import { useEffect } from 'react';

import {
  getCSFAQList,
  requestCSCampaign,
  requestCampaignHistory,
  requestOngoingCampaigns,
} from 'apis/cs';
import { useErrorModal } from 'context';
import { useCustomRecoilState, useQueryParams } from 'hooks';

export const useCSPage = () => {
  const { openModal, closeModal } = useErrorModal();

  const {
    state: { isTest, parameter, mediaKey },
    setState: { setCsInfo, setParameter },
  } = useCustomRecoilState();

  const { params } = useQueryParams();

  useEffect(() => {
    if (params) setParameter(params);

    return () => {
      closeModal();
    };
  }, [params]);

  useEffect(() => {
    const csParameter = {
      app_key: mediaKey || '',
      usn: parameter?.usn || '',
      adid: parameter?.adid || '',
      idfa: parameter?.idfa || '',
    };

    const fetchData = async () => {
      try {
        getCSFAQList(isTest).then((faqList) => {
          setCsInfo((prev) => ({ ...prev, faqList: faqList?.FaqList }));
        });

        // 다른 요청들 병렬 처리 및 즉시 상태 업데이트
        requestCSCampaign(csParameter, isTest).then((csCampaign) => {
          setCsInfo((prev) => ({
            ...prev,
            csCampaigns: csCampaign?.CSCampaigns || [],
          }));
        });

        requestCampaignHistory(csParameter, isTest).then((csHistory) => {
          setCsInfo((prev) => ({
            ...prev,
            completedCampaigns: csHistory?.CompletedCampaigns,
          }));
        });

        requestOngoingCampaigns({ app_key: mediaKey, ...parameter }, isTest).then(
          (ongoingCampaigns) => {
            setCsInfo((prev) => ({
              ...prev,
              ongoingCampaigns: ongoingCampaigns?.MultiRewardCampaigns || [],
            }));
          }
        );
      } catch (err) {
        openModal(err.message || '', closeModal);
        setCsInfo(null);
      }
    };

    if (!parameter) return;

    fetchData();
  }, [parameter]);
};
