import { useNavigate } from 'react-router-dom';

import { getCampaignList } from 'apis';
import { useErrorModal } from 'context';
import { useCustomRecoilState, useFetchRVInfoList, useSDKInterface } from 'hooks';
import { ConvertCampaignFormat } from 'utils';

export function useFetchCampaignList() {
  const { state, setState } = useCustomRecoilState();

  const { mediaKey, parameter, tabList, isViewMode } = state;
  const {
    setCampaignList,
    setChoiceOfferCampaign,
    setIsTest,
    setTotalReward,
    setCurrency,
    setIsNoAd,
    setIs501Error,
  } = setState;

  const {
    onOfferwallLoadFailed,
    onOfferwallLoadSuccess,
    isTestMode,
    setRewardCurrency,
    closeOfferwall,
    setTotalReward: setSDKTotalReward,
  } = useSDKInterface();
  const { openModal, openCustomModal } = useErrorModal();
  const navigate = useNavigate();
  const fetchRVList = useFetchRVInfoList();

  const fetchCampaignData = async () => {
    try {
      const campaignList = await getCampaignList({ ...parameter, app_key: mediaKey });
      onOfferwallLoadSuccess();

      const convertedCampaignList = ConvertCampaignFormat(tabList, campaignList);
      const totalReward = campaignList?.TotalRewardQuantity;
      const currency = campaignList?.RewardCurrency;
      const isTest = campaignList?.IsTest;

      const choiceOffer =
        convertedCampaignList?.[0]?.campaigns?.find((campaign) => campaign?.IsChoiceOffer) || null;

      setChoiceOfferCampaign(choiceOffer);

      setCampaignList(convertedCampaignList);

      setTotalReward(totalReward);
      setSDKTotalReward([totalReward]);

      setCurrency(currency);
      setRewardCurrency([currency]);

      setIsTest(isTest);
      isTestMode([isTest]);
      await fetchRVList();
    } catch (e) {
      onOfferwallLoadFailed();

      if (e?.code === 501) {
        setIs501Error(true);
        if (isViewMode) return;
        return openModal(
          "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
        );
      } else if (e?.code === 980) {
        setIsNoAd(true);
        if (isViewMode) return;
        return openCustomModal({
          title: '확인',
          message:
            '참여 가능한 광고가 없습니다. 자세한 안내를 보시려면 아래 이동 버튼을 눌러주세요.',
          confirmButtonText: '이동',
          onConfirm: () => {
            closeOfferwall();
            navigate('/cs');
          },
          cancelButtonText: '닫기',
          onCancel: () => {
            closeOfferwall();
          },
        });

        /** no ad modal  */
      }
      if (isViewMode) return;
      else openModal(e.message || '');
    }
  };

  return fetchCampaignData;
}
