const NotificationList = [
  '캠페인 미션 완료 시 리워드가 지급되며, 기존 이벤트 참여 이력이 있는 경우 리워드가 지급되지 않습니다.',
  '네트워크 장애가 발생한 경우, 리워드 지급이 지연될 수 있습니다.',
  '캠페인 참여를 통해 지급된 리워드는 재전환 및 전환 취소되지 않으며, 유효기간 내 미사용 리워드는 자동 소멸될 수 있습니다.',
  '리워드의 종류 및 지급 수량은 회사 정책에 의해 변경될 수 있습니다.',
  'Wi-Fi 환경이 아닌 경우 데이터 이용료가 발생할 수 있습니다.',
  '본 캠페인은 사정에 따라 사전 공지 없이 내용이 수정되거나 종료될 수 있습니다.',
  '본 캠페인은 (주)애드팝콘이 제공합니다.',
  '캠페인 문의는 고객 센터를 통해 문의 가능합니다.',
];

export const Notification = () => {
  return (
    <div className="bg-zigzagGray-100 p-20 flex flex-col gap-10">
      <span className="text-zigzagGray-700 text-14 font-bold">유의사항</span>
      <ul className="ml-5 flex flex-col font-regular text-13 relative list-disc list-inside text-zigzagGray-500">
        {NotificationList.map((item) => {
          return <li key={item}>{item}</li>;
        })}
      </ul>
    </div>
  );
};
