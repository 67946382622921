import NoAd from 'assets/no-ad.svg';
import { useCustomRecoilState } from 'hooks';

const viewModeMessages = {
  default: '잠시 후 다시 확인 부탁드릴게요',
  noAd: '현재 캠페인의 참여 한도가 소진되어 참여할 수 없습니다.',
  error501: '더 나은 맞춤형 광고와 리워드를 위해 \n추적을 활성화해 주세요.',
};

export const NoCampaign = () => {
  const {
    state: { isNoAd, is501Error },
  } = useCustomRecoilState();

  return (
    <div className="flex flex-col gap-[16px] items-center h-full self-center justify-center">
      <img src={NoAd} alt="시계" width={72} height={72} />
      <div className="flex flex-col items-center">
        <span className="text-[#394047] font-semibold text-[18px] leading-[28px]">
          참여 가능한 광고가 없습니다.
        </span>
        <span className="text-center whitespace-pre text-[14px] leading-[20px] text-[#808C99] font-normal">
          {`${
            isNoAd
              ? viewModeMessages.isNoAd
              : is501Error
              ? viewModeMessages.error501
              : viewModeMessages.default
          }`}
        </span>
      </div>
    </div>
  );
};
