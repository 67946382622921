import { useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

export const CampaignOverview = ({ Campaign }) => {
  const {
    state: { custom, currency, isLandscape },
  } = useCustomRecoilState();
  const { FeedMainImgURL, FeedDetailImgURL, IconImgURL } = Campaign;

  const imgSrc = FeedDetailImgURL || FeedMainImgURL;

  const MultiRewardConditionDesc = () => {
    return (
      <div
        style={{
          color: custom.primaryColor,
        }}
        className="text-[12px] leading-[16px] py-[6px] px-[12px] border border-[#D8E9FD] rounded-full w-fit"
      >
        {Campaign?.MultiRewardConditionDescription}
      </div>
    );
  };

  if (imgSrc && !isLandscape) {
    return (
      <div className="flex flex-col">
        <img
          style={{ borderStyle: 'none' }}
          alt="FeedImg"
          width="100%"
          height="auto"
          src={imgSrc}
          key={imgSrc}
        />
        <div className="flex flex-col px-16 pt-24 gap-12">
          {Campaign?.MultiRewardConditionDescription && <MultiRewardConditionDesc />}
          <div className="flex items-center justify-start">
            <div className="text-18 font-semibold text-gray-900 whitespace-pre-wrap">
              {Campaign?.FeedTitle}
              <br />
              {Campaign?.FeedDesc + ' '}
              <span
                className="font-semibold"
                style={{
                  color: custom.primaryColor,
                }}
              >
                {`${
                  Campaign?.RewardDetail
                    ? `${numberWithCommas(Campaign?.RewardDetail?.Quantity)} ${
                        Campaign?.RewardDetail?.Unit
                      }`
                    : `${
                        typeof Campaign?.Reward === 'string'
                          ? Campaign?.Reward
                          : `${numberWithCommas(Campaign?.Reward)} ${currency}`
                      }`
                } 지급!`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col px-16 pt-24 gap-12">
      {Campaign?.MultiRewardConditionDescription && <MultiRewardConditionDesc />}
      <div className="flex gap-12 items-center">
        <img
          style={{ borderStyle: 'none', borderRadius: '8px' }}
          alt="iconImg"
          width="60px"
          height="60px"
          src={IconImgURL || ''}
          key={IconImgURL}
        />
        <div className="text-[16px] tracking-[-0.5px] text-[#212529] font-semibold leading-[22.4px] text-start h-fit">
          {Campaign?.FeedTitle}
          <br />
          <span className="text-[#808C99] font-regular text-[14px] leading-[16.8px]">
            {(Campaign?.FeedDesc || '') + ' '}
            <span
              className="font-semibold"
              style={{
                color: custom.primaryColor,
              }}
            >
              {Campaign?.RewardDetail
                ? `${numberWithCommas(Campaign?.RewardDetail?.Quantity)} ${
                    Campaign?.RewardDetail?.Unit
                  }`
                : `${
                    typeof Campaign?.Reward === 'string'
                      ? Campaign?.Reward
                      : `${numberWithCommas(Campaign?.Reward)} ${currency}`
                  }`}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
