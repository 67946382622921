import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useErrorModal } from 'context';
import { useQueryParams, useSDKInterface } from 'hooks';

export const useUnauthorizedModal = () => {
  const { onOfferwallLoadSuccess } = useSDKInterface();

  useEffect(() => {
    onOfferwallLoadSuccess();
  }, []);

  const { onDisagreePrivacy, onAgreePrivacy, closeOfferwall } = useSDKInterface();
  const location = useLocation();
  const navigate = useNavigate();
  const { openCustomModal, closeModal } = useErrorModal();
  const { queryParams } = useQueryParams();

  const onClickAgree = () => {
    // agreement 파라미터의 값을 변경합니다.
    queryParams.set('agreement', 'true');
    const newSearch = queryParams.toString();
    // 변경된 쿼리 파라미터가 포함된 새로운 URL을 생성합니다.
    const newURL = location.pathname + '?' + newSearch;
    // 히스토리 스택에 새 항목을 추가하고, 백키를 눌러도 이전 페이지로 돌아가지 않도록 합니다.
    navigate(newURL, { replace: true });
    onAgreePrivacy();
  };

  const onClickDisAgree = (callBackFunc) => {
    onDisagreePrivacy([true]);
    openCustomModal({
      message: '개인정보 이용 및 수집에 동의하셔야 애드팝콘 이용이 가능합니다.',
      onConfirm: () => {
        const callback = callBackFunc || closeOfferwall;
        closeModal();
        callback();
      },
    });
  };

  return { onClickAgree, onClickDisAgree };
};
