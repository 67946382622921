import { useState } from 'react';

import arrowDropDown from 'assets/arrow_drop_down.svg';
import { useCustomRecoilState } from 'hooks';

import { ETCQuestions } from './etc-question';
import { SelectedCampaignBottomSheet } from './select-campaign-bottom-sheet';
import { useETCQuestion } from './use-etc-question';

export function CSRequest() {
  const {
    state: { csInfo },
  } = useCustomRecoilState();

  const { options, selectedOption } = useETCQuestion();

  const csCampaigns = csInfo?.csCampaigns || [];

  const [showBottomModal, setShowBottomModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const CSCampaigns = [...csCampaigns, { Auth: 'ETC', Title: '기타 문의 사항' }];

  return (
    <div className="px-[15px] py-[16px] flex flex-col">
      <div
        className="px-[16px] pyw-full h-[48px] border-gray-100 border rounded-[8px] flex items-center justify-between"
        role="presentation"
        onClick={() => setShowBottomModal(true)}
      >
        <div className={`${selectedCampaign ? 'text-gray-900' : 'text-gray-500'}`}>
          {(CSCampaigns.length &&
            CSCampaigns?.find((campaign) => campaign.Auth === selectedCampaign)?.Title) ||
            '문의 할 캠페인 선택'}
        </div>
        <img width={20} height={20} src={arrowDropDown} alt="더보기" />
      </div>
      {selectedCampaign === 'ETC' && (
        <ETCQuestions options={options} selectedOption={selectedOption} />
      )}
      {showBottomModal && (
        <SelectedCampaignBottomSheet
          {...{
            setShowBottomModal,
            selectedCampaign,
            setSelectedCampaign,
            CSCampaigns,
          }}
        />
      )}
    </div>
  );
}
