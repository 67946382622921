import { useEffect } from 'react';

import { useCustomRecoilState, useFetchTab, useQueryParams } from 'hooks';
import { numberWithCommas } from 'utils';

import { CampaignList } from './campaign-list';
import { UnauthorizedMessage } from './un-authorized-message';

export function CashNoteViewModePage() {
  const fetchTabInfo = useFetchTab();
  const { isAgreed } = useQueryParams();

  const {
    state: {
      tabList,
      totalReward,
      currency,
      selectedTab: { selectedTabId },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  useEffect(() => {
    if (tabList?.length !== 0) return;

    fetchTabInfo();
  }, []);

  if (tabList.length === 0) return null;

  return (
    <div className="w-full h-fit flex flex-col relative">
      <div className="sticky top-0 bg-white z-10">
        <div className="flex flex-col justify-start pb-12 px-20 pt-20 gap-5">
          <span className="font-semibold text-[16px] leading-[22px] text-[#0C1120]">
            오늘 적립할 수 있는 포인트
          </span>
          <span className="text-[#2D91FF] font-bold text-[24px] leading-[32px]">{`총 ${numberWithCommas(
            totalReward
          )} ${currency}`}</span>
        </div>

        <div className="px-20 grid grid-flow-col gap-16 break-keep border-b border-[#F4F7F9] overflow-x-scroll no-scrollbar">
          {tabList.map((tab) => (
            <div
              key={tab.TypeNo}
              role="presentation"
              onClick={() => {
                setSelectedTab((prev) => {
                  return { ...prev, selectedTabId: tab.TypeNo };
                });
              }}
              className={`py-10 text-16 leading-[26px] px-10 m-auto  flex justify-center items-center whitespace-nowrap ${
                tab.TypeNo === selectedTabId
                  ? 'text-[#0C1120] border-b-[2px] font-semibold border-[#0C1120]'
                  : 'text-[#728094] border-b-[2px] font-normal border-transparent'
              }`}
            >
              <span className="whitespace-nowrap">{tab.Name}</span>
            </div>
          ))}
        </div>
      </div>
      {!isAgreed && <UnauthorizedMessage />}
      {tabList.length > 0 && <CampaignList />}
    </div>
  );
}
