import axios from 'axios';

import { getCPMCampaignList } from 'apis';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export const useFetchCPMCampaign = () => {
  const {
    setState: { setCpmCampaignList },
    state: { parameter, mediaKey, isTest },
  } = useCustomRecoilState();

  const { onOfferwallLoadFailed } = useSDKInterface();

  const fetchCPMCampaignList = async () => {
    try {
      const cpmCampaignListData = await getCPMCampaignList(
        { ...parameter, app_key: mediaKey },
        isTest
      );

      const cpmCampaigns = [
        ...(cpmCampaignListData?.CPMCampaigns || []),
        ...(cpmCampaignListData?.RCPCCampaigns || []),
        ...(cpmCampaignListData?.CPCCampaigns || []),
      ];

      setCpmCampaignList(cpmCampaigns);

      const filteredCampaigns =
        cpmCampaigns?.filter((campaign) => campaign?.IntegrationTypeCode === 46) || [];

      await Promise.all(
        filteredCampaigns?.map(async (campaign) => {
          const impUrl = campaign?.ImpressionURLs?.length ? campaign?.ImpressionURLs[0] : null;
          if (impUrl) {
            try {
              await axios.get(impUrl); // 반환 없이 GET 요청만 수행
            } catch (error) {
              console.error(`Failed to fetch URL: ${impUrl}`, error);
            }
          }
        })
      );
    } catch (e) {
      onOfferwallLoadFailed();
    }
  };

  return fetchCPMCampaignList;
};
