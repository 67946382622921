export const ETCQuestions = ({ selectedOption, options }) => {
  return (
    <div className="flex flex-col gap-[10px] pt-[8px]">
      {options.map(({ value, label, onClick }) => (
        <div
          key={value}
          role="presentation"
          className="text-gray-500 text-[13px] flex gap-[8px]"
          onClick={() => onClick(value)}
        >
          <input type="radio" value={value} checked={selectedOption === value} readOnly />
          <span>{label}</span>
        </div>
      ))}

      <div
        name="message"
        className={`h-[120px] text-gray-400 text-[15px] p-[14px] border border-gray-100 rounded-[8px] bg-[#F5F5F5]`}
      >
        {options[selectedOption].placeholderMsg}
      </div>
    </div>
  );
};
