import { Link } from 'react-router-dom';

import arrowBack from 'assets/zig-zag/arrow-back.png';
import info from 'assets/zig-zag/info.png';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export function Header() {
  const {
    state: { custom },
  } = useCustomRecoilState();

  const { closeOfferwall } = useSDKInterface();

  return (
    <div className="h-[44px] flex w-[100vw] items-center justify-between px-[6px] py-[6px] bg-white z-20">
      <div className="flex w-fit shrink-0" onClick={closeOfferwall} role="presentation">
        <img width={44} height={44} src={arrowBack} alt="뒤로가기" />
      </div>
      <div className="flex gap-[2px] self-center overflow-hidden">
        <span className="font-bold text-zigzagGray-950 text-18 self-center truncate">
          {custom.offerwallTitle}
        </span>
        <Link to={'/cs'} className="flex shrink-0">
          <img
            className="w-[24px] h-[24px]"
            width={24}
            height={24}
            src={info}
            alt="더보기"
            role="presentation"
          />
        </Link>
      </div>
      <div className="size-44 shrink-0" />
    </div>
  );
}

export default Header;
