import { useEffect } from 'react';

import { useCustomRecoilState, useFetchTab } from 'hooks';

import { MainTabSection } from '../default';

export function TabController({ children }) {
  const fetchTabInfo = useFetchTab();

  const {
    state: { tabList },
  } = useCustomRecoilState();

  useEffect(() => {
    if (tabList?.length !== 0) return;

    fetchTabInfo();
  }, []);

  if (tabList?.length === 0) return null;

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="sticky top-0 bg-white z-10">
        <MainTabSection />
        {/** view mode 의 경우 subTabSection 제거 */}
      </div>
      {children}
    </div>
  );
}

export default TabController;
