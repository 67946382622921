import { useState } from 'react';

import { useErrorModal } from 'context';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export const useETCQuestion = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  const { openCustomModal, closeModal } = useErrorModal();

  const { closeNewInAppBrowser, closeOfferwall, onDisagreePrivacy } = useSDKInterface();
  const {
    state: { parameter, isNewInappBrowser, platform },
  } = useCustomRecoilState();

  const handleConfirmAlert = () =>
    openCustomModal({
      title: '개인정보 수집 해제',
      message: '개인정보 수집 해제가 완료되었습니다.',
      onConfirm: () => {
        closeModal();
        onDisagreePrivacy([false]);
        if (isNewInappBrowser) {
          return closeNewInAppBrowser();
        }
        closeOfferwall();
      },
      confirmButtonText: '확인',
    });

  /**ios */
  const handleIosAlert = () =>
    openCustomModal({
      title: '개인정보 수집 해제',
      message:
        '개인정보 수집 해제를 원하실 경우 아래의 경로로 설정이 필요합니다. \n`[설정 >개인정보 수집 해제 할 APP 선택 > 추적허용 ON설정을 OFF로 변경 or 설정 > 개인정보 보호> 추적 > 개인정보 수집 해제할 APP의 ON 설정을 OFF로 변경]',
      onConfirm: closeModal,
      confirmButtonText: '확인',
    });

  /**android */
  const handleAlert = () =>
    openCustomModal({
      title: '개인정보 수집 해제',
      message:
        '개인정보 수집을 해제하면 더 이상 애드팝콘 \n 서비스를 사용하실 수 없습니다.\n  그래도 진행하시겠습니까?',
      onCancel: () => {
        closeModal();
      },
      cancelButtonText: '아니오',
      onConfirm: handleConfirmAlert,
      confirmButtonText: '예',
    });

  const options = [
    {
      placeholderMsg: '해당 앱 고객센터로 문의해 주세요',
      value: 0,
      label: '리워드 지급이 완료됐다는데, 리워드가 안 들어왔어요.',
      onClick: (val) => setSelectedOption(val),
    },
    {
      placeholderMsg:
        '참여 이력에서 해당 광고를 참여했는지 다시 확인 부탁 드립니다. 참여 이력에도 없다면, 참여 대상이 아닌 광고이니 다른 광고를 참여해 주세요',
      value: 1,
      label: '이미 참여했다는 메시지만 나와요.',
      onClick: (val) => setSelectedOption(val),
    },
    ...(parameter
      ? [
          {
            placeholderMsg:
              '개인정보 수집을 해제하면 더 이상 애드팝콘\n 서비스를 사용하실 수 없습니다.',
            value: 2,
            label: '개인정보 수집을 해제하고 싶어요.',
            onClick: (val) => {
              setSelectedOption(val);
              if (platform === 'ios') {
                handleIosAlert();
              } else {
                handleAlert();
              }
            },
          },
        ]
      : []),
  ];

  return { options, selectedOption };
};
