export const CampaignJoinButton = ({ onConfirm }) => {
  return (
    <div className="flex justify-center h-[70px]">
      <div className="flex justify-center items-center h-[50px] my-10 rounded-[100px] bg-zigzagGray-950 text-white fixed bottom-0 w-[calc(100%-40px)]">
        <div
          className="flex justify-center items-center text-zigzagGray-50 font-bold"
          role="presentation"
          onClick={onConfirm}
        >
          참여하고 포인트 받기
        </div>
      </div>
    </div>
  );
};
