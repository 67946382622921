export const MAIN_TAB_TYPES = {
  전체: 0,
  간편적립: 1,
  참여적립: 2,
  클릭적립: 3,
  쇼핑적립: 4,
};

// 미디어 타입 키들을 상수로 관리
export const CUSTOM_MEDIA_TYPES = {
  CASH_NOTE: 'cashNote',
  ZIG_ZAG: 'zigZag',
  ZUM: 'zum',
};

export const CUSTOM_MEDIA_TYPE_MAPPER = {
  [CUSTOM_MEDIA_TYPES.CASH_NOTE]: [42430018, 901924092],
  [CUSTOM_MEDIA_TYPES.ZIG_ZAG]: [860337341, 74876820],
  [CUSTOM_MEDIA_TYPES.ZUM]: [55849288, 824622555],

  // 필요한 다른 매핑을 추가할 수 있습니다.
};

export const CAMPAIGN_TYPES = {
  WEBTOON: 'webtoon',
  NEWS: 'news',
  CPM: 'cpm',
  MULTIREWARD: 'multiReward',
  RV: 'rv',
  RCPC: 'rcpc',
};

export const CAMPAIGN_TYPE_MAPPER = {
  /**partiNo */
  [CAMPAIGN_TYPES.WEBTOON]: [53],
  [CAMPAIGN_TYPES.NEWS]: [46],
  [CAMPAIGN_TYPES.CPM]: [43],
  [CAMPAIGN_TYPES.MULTIREWARD]: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
  /**campaignKey */
  [CAMPAIGN_TYPES.RV]: ['559551593'],
  [CAMPAIGN_TYPES.RCPC]: ['958189455'],
};
