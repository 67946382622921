import { useCustomRecoilState } from 'hooks';

export const MainTabSection = () => {
  const {
    state: {
      tabList,
      selectedTab: { selectedTabId },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  return (
    <div className="h-48 px-10 flex break-keep border-b border-zigzagGray-30 overflow-x-scroll">
      {tabList?.map((tab) => (
        <div
          key={tab.TypeNo}
          role="presentation"
          onClick={() => {
            setSelectedTab((prev) => {
              return { ...prev, selectedTabId: tab.TypeNo };
            });
          }}
          className={`py-14 mx-6 text-16 font-semibold flex justify-center items-center ${
            tab.TypeNo === selectedTabId
              ? 'text-zigzagGray-950 border-b-[2px] border-zigzagGray-950'
              : 'text-zigzagGray-400 border-b-[2px] border-transparent'
          }`}
        >
          <span className="px-4 pt-1 whitespace-nowrap">{tab.Name}</span>
        </div>
      ))}
    </div>
  );
};
