export const CustomCampaignJoinButton = ({ onConfirm }) => {
  return (
    <div className="flex justify-center items-center h-[70px]">
      <div
        className="font-semibold fixed rounded-[16px] w-[90%] bottom-[20px] flex justify-center items-center text-white font-semibold bg-[#2D91FF] h-[56px]"
        role="presentation"
        onClick={onConfirm}
      >
        참여하고 적립받기
      </div>
    </div>
  );
};

export default CustomCampaignJoinButton;
