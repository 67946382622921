import NoAd from 'assets/no-ad.svg';
import { useCustomRecoilState } from 'hooks';

export const NoCampaign = () => {
  const {
    state: { isNoAd, is501Error },
  } = useCustomRecoilState();

  return (
    <div className="flex flex-col gap-[16px] items-center h-full self-center justify-center">
      <img src={NoAd} alt="시계" width={72} height={72} />
      <div className="flex flex-col items-center">
        <span className="text-[#394047] font-semibold text-[18px] leading-[28px]">
          {isNoAd || is501Error ? '참여 가능한 광고가 없습니다.' : '미션을 준비 중이에요'}
        </span>
        <span className="text-center whitespace-pre text-[14px] leading-[20px] text-[#808C99] font-normal">
          {'상단에 다른 미션들을 참여하고 \n리워드를 받아가세요'}
        </span>
      </div>
    </div>
  );
};
