import { Link } from 'react-router-dom';

import arrowBack from 'assets/arrow_back.svg';
import circleInfo from 'assets/circle-info.png';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export function Header() {
  const {
    state: { isViewMode },
  } = useCustomRecoilState();
  const { closeOfferwall } = useSDKInterface();

  return (
    <div className="h-[48px] flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20 text-gray-900">
      <div
        className="flex flex-row gap-[6px] truncate"
        onClick={closeOfferwall}
        role="presentation"
      >
        {!isViewMode && <img width={24} height={24} src={arrowBack} alt="뒤로가기" />}
      </div>
      <div className="text-[18px] leading-[25.2px] truncate font-[600] self-center">혜택 zum</div>
      <Link to={'/cs'} className="flex-shrink-0">
        <img
          className="w-[24px] h-[24px]"
          width={24}
          height={24}
          src={circleInfo}
          alt="더보기"
          role="presentation"
        />
      </Link>
    </div>
  );
}

export default Header;
