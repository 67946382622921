import { getRVInfoList } from 'apis';
import { useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';

export function useFetchRVInfoList() {
  const { isRewardVideoIntegrated } = useQueryParams();
  const {
    state: { platform, parameter, mediaKey, isTest },
    setState: { setRvInfoList },
  } = useCustomRecoilState();

  const { loadRewardVideo } = useSDKInterface();

  const fetchRVList = async () => {
    if (!isRewardVideoIntegrated || platform === 'ios') return;
    const RVParams = {
      mediaKey,
      adid: parameter?.adid || null,
      idfa: parameter?.idfa || null,
      usn: parameter?.usn || null,
    };

    try {
      const rvInfoListData = await getRVInfoList(RVParams, isTest);

      const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];

      setRvInfoList(
        rewardVideoInfo?.map((rv) => {
          return { ...rv, status: 'loading' };
        })
      );

      rewardVideoInfo
        ?.filter?.((rv) => rv?.PlacementTypeNo === 0)
        ?.forEach((rv) => {
          loadRewardVideo([rv?.IntegrationAppKey || '', rv?.IntegrationPlacementId || '', false]);
        });
    } catch (e) {
      return;
    }
  };

  return fetchRVList;
}
