import { CAMPAIGN_TYPES, MAIN_TAB_TYPES } from 'constants';

import { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { Footer } from 'components';
import { useCustomRecoilState, useFetchCPMCampaign, useFetchCampaignList } from 'hooks';
import { checkCampaignType, numberWithCommas } from 'utils';

import { NoCampaign } from './no-campaign';

import { CampaignRow } from '../default';

export function CampaignList() {
  const {
    state: {
      campaignList,
      cpmCampaignList,
      currency,
      selectedTab: { selectedTabId },
      selectedTabCampaigns,
      parameter,
      totalReward,
    },
  } = useCustomRecoilState();

  const fetchCampaignList = useFetchCampaignList();
  const fetchCPMCampaignList = useFetchCPMCampaign();

  const listRef = useRef(null);

  useEffect(() => {
    if (!parameter) return;
    if (campaignList?.length) return;

    fetchCampaignList();
  }, []);

  useEffect(() => {
    if (!parameter) return;
    if (selectedTabId !== MAIN_TAB_TYPES['간편적립']) return;
    if (cpmCampaignList?.length > 0) return;

    fetchCPMCampaignList();
  }, [selectedTabId]);

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.resetAfterIndex(0);
    listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
  }, [selectedTabCampaigns]);

  // const hasRVFloatingAd = rvInfoList?.find((rv) => rv.PlacementTypeNo === 1);

  return (
    <div className="px-[15px] flex flex-col w-full h-full overflow-hidden overscroll-none">
      {/* {hasRVFloatingAd && <RVAd />} */}
      {/* {showChoiceOffer && <FixedChoiceOffer />} */}
      <div className="flex gap-[2px] py-[12px] text-gray-900 text-[13px] leading-[18.2px] relative">
        총
        <div>
          {numberWithCommas(totalReward)}
          {currency}
        </div>
        적립 가능
      </div>

      <div className="grow mb-[20px]">
        {selectedTabCampaigns.length === 0 ? (
          <NoCampaign />
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={listRef}
                height={height}
                width={width}
                itemData={selectedTabCampaigns}
                itemSize={(idx) => {
                  return checkCampaignType(CAMPAIGN_TYPES.RCPC, selectedTabCampaigns?.[idx]?.Key)
                    ? 120
                    : (window.innerWidth - 30) * 0.52 + 80;
                }}
                itemCount={selectedTabCampaigns?.length}
              >
                {CampaignRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CampaignList;
