import { useEffect } from 'react';

import { useCustomRecoilState, useSDKInterface } from 'hooks';

import { DefaultPage } from './default-page';

export function BridgePage() {
  const {
    state: { parameter },
  } = useCustomRecoilState();

  const { getParameter } = useSDKInterface();

  useEffect(() => {
    getParameter();
  }, []);

  if (!parameter) return null;
  // if (!isAgreed) return <UnauthorizedPage isViewType={isViewType} />;

  return <DefaultPage />;
}

export default BridgePage;
