export const ParticipationStepMessageList = ({ participationStepMessageList }) => {
  if (!participationStepMessageList?.length) return null;
  return (
    <div className="flex flex-col">
      <div className="h-[8px] w-full bg-[#F8F9FA]"></div>
      <div className="py-[24px] flex flex-col gap-10 p-20 rounded-[12px] bg-zigzagGray-100">
        {participationStepMessageList?.map((message, idx) => {
          return (
            <div className="flex flex-col gap-10" key={message}>
              <span className="text-zigzagGray-700 font-bold text-14">참여방법{idx + 1}</span>
              <li className="list-disc list-inside text-zigzagGray-500 font-regular text-13">
                {message}
              </li>
            </div>
          );
        })}
      </div>
    </div>
  );
};
