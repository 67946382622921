import ReactDOM from 'react-dom';

import { RecoilRoot } from 'recoil';

import { AwsRum } from 'aws-rum-web';

import App from './App';
import './index.css';

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    endpoint: 'https://dataplane.rum.ap-northeast-1.amazonaws.com',
    telemetries: ['errors', 'http'],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'ap-northeast-1';

  const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  window.awsRum = awsRum;
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById('root')
);
