import { CUSTOM_MEDIA_TYPES } from 'constants';

import { useEffect } from 'react';

import { useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';
import { getCustomMediaType } from 'utils';

import { MainPage } from './main-page';

export function Offerwall() {
  const {
    state: { parameter, mediaKey },
    setState: { setCustom },
  } = useCustomRecoilState();
  const { getParameter } = useSDKInterface();
  const { offerwallTitle, primaryColor, startTabIndex } = useQueryParams();

  useEffect(() => {
    getParameter();
    const mediaType = getCustomMediaType(mediaKey);
    const isZigZag = mediaType === CUSTOM_MEDIA_TYPES.ZIG_ZAG;

    /** 임시 : zigzag 의 경우 primary color 강제 변경 */
    setCustom((prev) => {
      return {
        offerwallTitle: isZigZag
          ? '포인트 모으기'
          : offerwallTitle
          ? offerwallTitle
          : prev.offerwallTitle,
        primaryColor: isZigZag ? '#FA6EE3' : primaryColor ? primaryColor : prev.primaryColor,
        startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
      };
    });
  }, []);

  if (!parameter) return null;

  return <MainPage />;
}

export default Offerwall;
