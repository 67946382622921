import { MAIN_TAB_TYPES } from 'constants';

import { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import {
  useCustomRecoilState,
  useFetchCampaignList,
  useFetchCPMCampaign,
  useSDKInterface,
} from 'hooks';

import { CampaignRow } from './campaign-row';

import { NoCampaign } from '../../viewmode/no-campaign';

export function CampaignList() {
  const CASHNOTE_HEIGHT = 673;

  const { viewModeWebViewHeight } = useSDKInterface();
  const {
    state: {
      campaignList,
      cpmCampaignList,

      selectedTab: { selectedTabId },
      selectedTabCampaigns,

      parameter,
    },
  } = useCustomRecoilState();
  const listRef = useRef(null);
  const fetchCampaignList = useFetchCampaignList();
  const fetchCPMCampaignList = useFetchCPMCampaign();

  useEffect(() => {
    if (!parameter) return;
    if (campaignList?.length) return;

    viewModeWebViewHeight([CASHNOTE_HEIGHT]);
    fetchCampaignList();
  }, []);

  useEffect(() => {
    if (!parameter) return;
    if (selectedTabId !== MAIN_TAB_TYPES['간편적립']) return;
    if (cpmCampaignList?.length > 0) return;

    fetchCPMCampaignList();
  }, [selectedTabId]);

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.resetAfterIndex(0);
    listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
  }, [selectedTabCampaigns]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden overscroll-none">
      <div className="flex flex-col h-[533px] pb-20">
        <div className="grow">
          {selectedTabCampaigns.length === 0 ? (
            <NoCampaign />
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <VariableSizeList
                  ref={listRef}
                  height={height}
                  width={width}
                  itemData={
                    selectedTabCampaigns.length > 5
                      ? selectedTabCampaigns?.slice(0, 5)
                      : selectedTabCampaigns
                  }
                  itemSize={(idx) => {
                    return selectedTabCampaigns?.[idx]?.Key === '958189455' ? 120 : 96;
                  }}
                  itemCount={
                    selectedTabCampaigns.length > 5
                      ? selectedTabCampaigns?.slice(0, 5)?.length
                      : selectedTabCampaigns?.length
                  }
                >
                  {CampaignRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          )}
        </div>
        <SeeMore />
      </div>
    </div>
  );
}

const SeeMore = () => {
  const { openOfferwall } = useSDKInterface();

  return (
    <div className="w-full px-20 flex justify-end items-center">
      <button
        onClick={openOfferwall}
        className="flex gap-4 items-center text-[#728094] font-semibold text-[14px] leading-[22px] h-32"
      >
        <span>더보기</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.11168 3.04781C6.34599 2.81349 6.72589 2.81349 6.96021 3.04781L11.4882 7.57581C11.7225 7.81012 11.7225 8.19002 11.4882 8.42434L6.96021 12.9523C6.72589 13.1867 6.34599 13.1867 6.11168 12.9523C5.87736 12.718 5.87736 12.3381 6.11168 12.1038L10.2154 8.00007L6.11168 3.89633C5.87736 3.66202 5.87736 3.28212 6.11168 3.04781Z"
            fill="#728094"
          />
        </svg>
      </button>
    </div>
  );
};

export default CampaignList;
