import { useEffect, useState } from 'react';

import { numberWithCommas } from 'utils';

function getDeterministicRandom(seed, min, max) {
  const x = Math.abs(Math.sin(seed)) * 10000;
  return Math.floor(x % (max - min + 1)) + min;
}

function generateDailyNumbers() {
  const today = new Date();
  const seed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
  const x = getDeterministicRandom(seed, 1000, 1500);

  return x;
}

export function DailyParticipation() {
  const [numbers, setNumbers] = useState();

  useEffect(() => {
    const number = generateDailyNumbers();
    const hour = new Date().getHours();

    setNumbers({
      x: number,
      y: number + Math.ceil(number / 2) * hour,
    });
  }, []);

  return (
    <span className="text-bridgeFontSize-12 font-[400] text-bridgeColor-gray-400">{`지금까지 ${numberWithCommas(
      numbers?.y
    )}명 참여`}</span>
  );
}

export default DailyParticipation;
