import { useErrorModal } from 'context';
import { useSDKInterface } from 'hooks';

export function Footer() {
  const { openCustomModal, closeModal } = useErrorModal();

  const { openWebBrowser } = useSDKInterface();

  const showModal = () => {
    openCustomModal({
      message: '애드팝콘 홈페이지로 이동 하시겠습니까?',
      confirmButtonText: '이동',
      onConfirm: () => window.open('https://reward.adpopcorn.com/', '_blank'),
      cancelButtonText: '닫기',
      onCancel: closeModal,
    });
  };

  return (
    <div className="mx-[-15px] h-[38px]">
      <div className="bg-[#F8F9FA] py-[12px] px-[15px] flex justify-between text-[12px] text-[#9CA6AF] leading-[14.32px]">
        <span role="presentation" onClick={showModal}>
          Adpopcorn All Rights Reserved
        </span>
        <span
          role="presentation"
          onClick={(e) => {
            openWebBrowser(['https://reward.adpopcorn.com/policy/privacy']);
          }}
        >
          개인정보처리방침
        </span>
      </div>
    </div>
  );
}
