import { CAMPAIGN_TYPES } from 'constants';

import { useNavigate } from 'react-router-dom';

import RVIcon from 'assets/rv-icon.png';
import { RcpcAd } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { checkCampaignType, numberWithCommas } from 'utils';

export const CampaignRow = (props) => {
  const { data, index, style } = props;
  const campaign = data?.[index];

  const isRV = checkCampaignType(CAMPAIGN_TYPES.RV, campaign?.Key);
  const isRCPC = checkCampaignType(CAMPAIGN_TYPES.RCPC, campaign?.Key);

  return (
    <div style={style} key={index}>
      {isRCPC ? (
        <RcpcAd campaign={campaign} />
      ) : isRV ? (
        <RVCampaign campaign={campaign} />
      ) : (
        <DefaultCampaign campaign={campaign} style={style} />
      )}
    </div>
  );
};

/**RVCampaign */
const RVCampaign = ({ campaign }) => {
  const {
    state: { currency },
  } = useCustomRecoilState();

  const { handleClickRV } = useClickCampaign();
  return (
    <div
      className="px-16 py-18 inline-flex justify-between border-b border-b-zigzagGray-100 w-full active:bg-zigzagGray-100"
      role="presentation"
      onClick={() => handleClickRV(campaign)}
    >
      <img className="rounded-[12px] w-52 h-52 min-w-52" alt="비디오 광고 이미지" src={RVIcon} />
      <div className="flex flex-col w-full gap-4 whitespace-nowrap overflow-hidden text-ellipsis px-12 justify-center">
        <div className="text-15 text-zigzagGray-900 font-bold truncate">
          영상 보고 포인트 적립하기
        </div>
        <div className="text-12 text-zigzagGray-500 truncate">
          동영상을 시청만 하면 포인트를 드려요
        </div>
      </div>
      <div className="flex justify-center">
        <span
          className={`text-13 text-white font-semibold rounded-[16px] min-w-73 px-6 py-8 break-keep flex justify-center items-center bg-zigzagPink-400 self-center group-active:bg-zigzagPink-500`}
        >
          {`${campaign?.Point}${currency}`}
        </span>
      </div>
    </div>
  );
};

/**Default Campaign */
const DefaultCampaign = ({ campaign }) => {
  const {
    state: { currency = 'P' },
  } = useCustomRecoilState();
  const { handleClickCampaign } = useClickCampaign();
  const navigate = useNavigate();

  return (
    <div
      className="px-16 py-18 flex justify-between border-b border-b-zigzagGray-100 w-full active:bg-zigzagGray-100 group"
      role="presentation"
      onClick={() => handleClickCampaign(campaign)}
    >
      <div className="flex min-w-full">
        {campaign.IconImgURL || campaign.IconUrl ? (
          <img
            className="rounded-[12px] w-52 h-52 min-w-52"
            alt={campaign.Title}
            src={campaign.IconImgURL || campaign.IconUrl}
          />
        ) : (
          <div className="min-w-52 h-52 rounded-[12px] bg-gray-300 inline-flex" />
        )}
        <div className="flex flex-col w-full gap-4 whitespace-nowrap overflow-hidden text-ellipsis px-12 justify-center">
          <div className="text-15 text-zigzagGray-900 font-bold truncate">{campaign.Title}</div>
          <div className="text-12 text-zigzagGray-500 truncate">
            {campaign.Desc || campaign.Description || ''}
          </div>
        </div>
        <div className="flex justify-center max-w-[100px] whitespace-nowrap">
          <span
            className={`truncate text-13 text-white font-semibold rounded-[16px] min-w-73 px-6 py-8 flex justify-center items-center bg-zigzagPink-400 group-active:bg-zigzagPink-500 self-center`}
          >
            {campaign?.RewardDetail
              ? `${numberWithCommas(campaign?.RewardDetail?.Quantity)} ${
                  campaign?.RewardDetail?.Unit
                }`
              : `${
                  typeof campaign?.Reward === 'string'
                    ? campaign?.Reward
                    : `${numberWithCommas(campaign?.Reward)} ${currency}`
                }`}
          </span>
        </div>
      </div>
    </div>
  );
};
