export const UnauthorizedMessage = () => {
  return (
    <div className="h-full w-full bg-white flex justify-center items-center flex-col gap-4">
      <span className="text-[#394047] font-semibold leading-[28px] text-[18px]">
        개인정보 이용 및 수집 동의가 필요해요
      </span>
      <span className="text-[#808C99] font-regular leading-[20px] text-[14px]">{`새로고침 후 개인정보 수집 동의에 "동의"해 주세요.`}</span>
    </div>
  );
};
