export const ParticipationStepMessageList = ({ participationStepMessageList }) => {
  if (!participationStepMessageList?.length) return null;
  return (
    <div className="py-24 px-16 flex flex-col gap-[33px] tracking-[-0.5px]">
      {participationStepMessageList?.map((message, idx) => {
        return (
          <div className="flex flex-col gap-6 items-start text-[14px]" key={message}>
            <span className="text-[#212529] font-medium leading-[18.2px]">STEP {idx + 1}</span>
            <span className="text-[#66737F] leading-[16.8px] font-regular">{message}</span>
          </div>
        );
      })}
    </div>
  );
};
