import { useCustomRecoilState } from 'hooks';

export const DetailRewardConditionMessageList = () => {
  const {
    state: { selectedCampaign },
  } = useCustomRecoilState();

  if (!selectedCampaign?.DetailRewardConditionMessageList) return null;
  return (
    <div className="px-16 pb-24">
      <ul className="list-inside list-disc text-[#808C99] font-[400] tracking-[-0.4px] leading-[18.2px] text-[13px] flex flex-col gap-[2px] justify-start text-start relative">
        {selectedCampaign?.DetailRewardConditionMessageList?.map((message) => {
          return <li key={message}>{message}</li>;
        })}
      </ul>
    </div>
  );
};
