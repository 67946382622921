export const BottomSheet = ({ content, onClose }) => {
  return (
    <div
      className="z-20 fixed top-0 size-full bg-[rgba(0,0,0,0.4)]"
      role="presentation"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="fixed bottom-0 bg-[#ffffff] w-full max-h-[calc(100%-50px)] rounded-t-[8px] ">
        <div className="w-full h-[16px] rounded-t-[8px] flex justify-center items-center">
          <div className="w-[40px] h-[4px] bg-[#E9ECEF] rounded-full"></div>
        </div>
        {content}
      </div>
    </div>
  );
};
