import { CUSTOM_MEDIA_TYPE_MAPPER } from 'constants';

export const getCustomMediaType = (targetMediaKey) => {
  const targetMedia = Object.entries(CUSTOM_MEDIA_TYPE_MAPPER).find(([_, mediaKeys]) =>
    mediaKeys.includes(targetMediaKey)
  );

  if (!targetMedia) return null;
  const [mediaType] = targetMedia;

  return mediaType;
};
