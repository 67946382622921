import { useEffect } from 'react';

import { useCustomRecoilState, useFetchTab, useSDKInterface } from 'hooks';

import { MainTabSection } from './main-tab-section';
import { SubTabSection } from './sub-tab-section';

export function TabController({ children }) {
  const fetchTabInfo = useFetchTab();
  const { logging } = useSDKInterface();

  const {
    state: {
      tabList,
      selectedTab: { selectedTabId },
      isTest,
      custom,
    },
  } = useCustomRecoilState();

  useEffect(() => {
    const id = selectedTabId?.toString() || '';
    if (!id) return;

    logging(['impression_offerwall_tab', id]);
  }, [selectedTabId]);

  useEffect(() => {
    if (tabList?.length !== 0) return;

    fetchTabInfo();
  }, []);

  if (tabList.length === 0) return null;

  return (
    <div className="w-full h-full flex flex-col relative">
      {isTest && (
        <span
          style={{
            color: custom.primaryColor,
          }}
          className={`absolute top-[calc(50%)] left-[calc(50%-30px)] z-30`}
        >
          Test Mode
        </span>
      )}
      <div className="sticky top-0 bg-white z-10">
        <MainTabSection />
        <SubTabSection />
      </div>
      {children}
    </div>
  );
}

export default TabController;
