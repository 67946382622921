import { useCustomRecoilState } from 'hooks';

export function Header() {
  const {
    state: { custom },
  } = useCustomRecoilState();

  /** view mode 인 경우 뒤로가기 버튼 과 cs 버튼을 제거 */
  return (
    <div className="h-[44px] flex w-[100vw] overflow-hidden items-center flex-start px-[15px] py-[10px] bg-white z-20 tracking-[-0.5px]">
      <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">
        {custom.offerwallTitle}
      </div>
    </div>
  );
}

export default Header;
