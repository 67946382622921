import { useCustomRecoilState } from 'hooks';

export const MainTabSection = () => {
  const {
    state: {
      tabList,
      selectedTab: { selectedTabId },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  return (
    <div className="grid grid-flow-col text-[16px] leading-[22.4px] border-b border-gray-100 font-400 break-keep overflow-x-scroll">
      {tabList?.map((tab) => (
        <div
          key={tab.TypeNo}
          role="presentation"
          onClick={() => {
            setSelectedTab((prev) => {
              return { ...prev, selectedTabId: tab.TypeNo };
            });
          }}
          className={`py-[10px] px-10 m-auto flex justify-center items-center whitespace-nowrap ${
            tab.TypeNo === selectedTabId
              ? 'text-gray-900 font-bold border-b-[2px] border-gray-900'
              : 'text-gray-500'
          }`}
        >
          {tab.Name}
        </div>
      ))}
    </div>
  );
};
