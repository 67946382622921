import { CAMPAIGN_TYPES } from 'constants';

import RVFeedBanner from 'assets/rv-feed-banner.svg';
import { RcpcAd } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { checkCampaignType, numberWithCommas } from 'utils';

export const CampaignRow = (props) => {
  const { data, index, style } = props;
  const campaign = data?.[index];

  const isRV = checkCampaignType(CAMPAIGN_TYPES.RV, campaign?.Key);
  const isRCPC = checkCampaignType(CAMPAIGN_TYPES.RCPC, campaign?.Key);

  return (
    <div style={style} key={index}>
      {isRCPC ? (
        <RcpcAd campaign={campaign} />
      ) : isRV ? (
        <RVCampaign campaign={campaign} />
      ) : (
        <DefaultCampaign campaign={campaign} style={style} />
      )}
    </div>
  );
};

/**RVCampaign */
const RVCampaign = ({ campaign }) => {
  const {
    state: { currency },
  } = useCustomRecoilState();

  const { handleClickRV } = useClickCampaign();

  return (
    <div
      className="w-full rounded-[10px] shadow-card"
      role="presentation"
      onClick={() => handleClickRV(campaign)}
    >
      <img
        className="w-full rounded-t-[10px] object-cover"
        alt="비디오 광고 이미지"
        src={RVFeedBanner}
      />

      <div className="p-[16px] flex justify-between items-center">
        <div className="flex flex-col gap-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
          <div className="text-[14px] text-gray-900 leading-[19.6px] truncate">
            영상 보고 포인트 적립하기
          </div>
          <div className="text-[13px] text-gray-500 leading-[15.6px] truncate">
            동영상을 시청만 하면 포인트를 드려요
          </div>
        </div>
        <div className="flex justify-center">
          <span
            className={`text-14 text-[#2474EE] font-bold rounded-[8px] min-w-73 px-6 py-4 break-keep whitespace-pre flex justify-center items-center bg-[#F3FAFF] self-center`}
          >
            {`${campaign?.Point}${currency} 지급`}
          </span>
        </div>
      </div>
    </div>
  );
};

/**Default Campaign */
const DefaultCampaign = ({ campaign, style }) => {
  const {
    state: { installedCampaignList, currency = 'P' },
  } = useCustomRecoilState();
  const { handleClickCampaign } = useClickCampaign();

  return (
    <div
      className="w-full rounded-[10px] shadow-card"
      role="presentation"
      onClick={() => handleClickCampaign(campaign)}
    >
      {campaign.FeedMainImgURL || campaign.ImageUrl ? (
        <img
          className="w-full rounded-t-[10px] object-cover"
          alt={campaign.Title}
          src={campaign.FeedMainImgURL || campaign.ImageUrl || ''}
          style={{ height: style?.height ? style?.height - 80 : 173 }}
        />
      ) : (
        <div
          className="w-full rounded-t-[10px] bg-gray-300"
          style={{
            height: style?.height ? style?.height - 80 : 173,
          }}
        />
      )}
      <div className="p-[16px] flex justify-between items-center">
        <div className="flex flex-col gap-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
          <div className="text-[14px] text-gray-900 leading-[19.6px] truncate">
            {campaign.Title}
          </div>
          <div className="text-[13px] text-gray-500 leading-[15.6px] truncate">
            {campaign.Desc || campaign.Description || ''}
          </div>
        </div>

        <div className="max-w-[100px] flex justify-center">
          <span
            className={`text-14 text-[#2474EE] font-bold rounded-[8px] min-w-73 px-6 py-4 break-keep whitespace-pre flex justify-center items-center bg-[#F3FAFF] self-center`}
          >
            {installedCampaignList?.includes(campaign.Key) ? (
              <span className="text-nowrap">설치 확인</span>
            ) : (
              <span className="truncate">
                {campaign?.RewardDetail
                  ? `${numberWithCommas(campaign?.RewardDetail?.Quantity)} ${
                      campaign?.RewardDetail?.Unit
                    }`
                  : `${
                      typeof campaign?.Reward === 'string'
                        ? campaign?.Reward
                        : `${numberWithCommas(campaign?.Reward)} ${currency}`
                    }`}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
