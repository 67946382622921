import { useEffect } from 'react';

import { useCustomRecoilState, useFetchTab, useSDKInterface } from 'hooks';

import { MainTabSection } from './main-tab-section';
import { SubTabSection } from './sub-tab-section';

export function TabController({ children }) {
  const fetchTabInfo = useFetchTab();

  const {
    state: {
      tabList,
      selectedTab: { selectedTabId },
    },
  } = useCustomRecoilState();
  const { logging } = useSDKInterface();

  useEffect(() => {
    const id = selectedTabId?.toString() || '';
    if (!id) return;

    logging(['impression_offerwall_tab', id]);
  }, [selectedTabId]);

  useEffect(() => {
    if (tabList?.length !== 0) return;

    fetchTabInfo();
  }, []);

  if (tabList?.length === 0) return null;

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="sticky top-0 bg-white z-10">
        <MainTabSection />
        <SubTabSection />
      </div>
      {children}
    </div>
  );
}

export default TabController;
