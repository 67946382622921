import { createContext, useContext } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';

// 모달을 관리하는 Context 생성
const ErrorModalContext = createContext();

// ErrorModalProvider 정의
export function ErrorModalProvider({ children }) {
  const modalProps = useModal();

  return (
    <ErrorModalContext.Provider value={modalProps}>
      {children}
      {modalProps?.modalContent && <Modal />}
    </ErrorModalContext.Provider>
  );
}

// 사용자 정의 Hook: 에러 모달에 접근하기 위한 Hook
export function useErrorModal() {
  const context = useContext(ErrorModalContext);
  if (context === undefined) {
    throw new Error('useErrorModal must be used within an ErrorModalProvider');
  }
  return context;
}

export default ErrorModalProvider;
