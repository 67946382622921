import { Link, useNavigate } from 'react-router-dom';

import arrowBack from 'assets/arrow_back.svg';
import cashNoteHelp from 'assets/cashnote-help.svg';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export function Header({ title }) {
  const {
    state: { isNewInAppBrowser },
  } = useCustomRecoilState();
  const navigate = useNavigate();
  const { onClosedDetailPage, closeNewInAppBrowser } = useSDKInterface();

  const onClickBackButton = () => {
    onClosedDetailPage();

    if (isNewInAppBrowser) {
      closeNewInAppBrowser();
    }
    return navigate(-1);
  };

  return (
    <div className="gap-6 h-[44px] flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20 tracking-[-0.5px]">
      <img
        width={24}
        height={24}
        src={arrowBack}
        alt="뒤로가기"
        onClick={onClickBackButton}
        role="presentation"
      />

      <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">{title}</div>
      <Link to={'/cs'} className="flex-shrink-0">
        <div className="flex items-center gap-4 text-[#44546F] font-semibold text-[14px] leading-[22px] whitespace-pre">
          <img
            className="w-[20px] h-[20px]"
            width={20}
            height={20}
            src={cashNoteHelp}
            alt="더보기"
            role="presentation"
          />
          <span>고객센터</span>
        </div>
      </Link>
    </div>
  );
}

export default Header;
