import { useCallback, useMemo } from 'react';

import { useCustomRecoilState } from 'hooks';

// sdkConfig.js
export const SDK_ACTIONS = {
  getParameter: {
    params: null,
  },
  showRewardVideo: {
    params: null,
  },
  onOfferwallLoadFailed: {
    params: null,
  },
  onOfferwallLoadSuccess: {
    params: null,
  },
  onClosedDetailPage: {
    params: null,
  },
  closeOfferwall: {
    params: null,
  },
  executeAppAndReward: {
    params: null,
  },
  closeNewInAppBrowser: {
    params: null,
  },
  onOpenedDetailPage: { params: null },
  openOfferwall: {
    params: null,
  },
  onAgreePrivacy: { params: null },
  onDisagreePrivacy: {
    params: ['callback'],
  },
  viewModeWebViewHeight: {
    params: ['contentsHeight'],
  },
  setTotalReward: {
    params: ['totalReward'],
  },
  setRewardCurrency: {
    params: ['currency'],
  },
  openWebBrowser: {
    params: ['url'],
  },
  openNewInAppBrowser: {
    params: ['url'],
  },
  joinCampaign: {
    params: ['jsonParameter'],
  },
  isTestMode: {
    params: ['enable'],
  },
  loadRewardVideo: {
    params: ['appKey', 'placementId', 'showProgress'],
  },
  logging: {
    params: ['eventName', 'eventParam'],
  },
  completeCampaign: { params: ['jsonParameter'] },
  checkCampaignPackageTargetList: {
    params: ['targetPackageNameList', 'detargetPackageNameList'],
  },
  checkCampaign: {
    params: ['jsonParameter'],
  },
};

export const useSDKInterface = () => {
  const {
    state: { platform },
  } = useCustomRecoilState();

  const sendMessageToSDK = useCallback(
    (action, params) => {
      if (platform === 'ios') {
        const message = {};
        message.action = action;

        // params가 있는 경우 각 key-value 쌍을 추가
        if (params) {
          Object.entries(params).forEach(([key, value]) => {
            message[key] = value;
          });
        }

        return window?.webkit?.messageHandlers?.APRewardOfferwall?.postMessage?.(message);
      } else {
        if (params) {
          // Android에서는 키 필요없이 값만 쏙쏙 뽑아서 메서드 호출
          const values = Object.values(params);
          return window?.APRewardOfferwall?.[action]?.(...values);
        } else {
          return window?.APRewardOfferwall?.[action]?.();
        }
      }
    },
    [platform]
  );

  const methods = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(SDK_ACTIONS).map(([action, { params }]) => {
          if (!params) return [action, () => sendMessageToSDK(action, null)];
          return [
            action,
            (args) =>
              sendMessageToSDK(
                action,
                Object.fromEntries(params.map((param, idx) => [param, args[idx]]))
              ),
          ];
        })
      ),
    [sendMessageToSDK]
  );

  return methods;
};
