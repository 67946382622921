import { useEffect } from 'react';

import { useCustomRecoilState, useFetchBridgeInfo } from 'hooks';

import { BridgeOverview, CarouselSection, Header, Loading, Notification } from './default';

export function DefaultPage() {
  const fetchBridgeInfo = useFetchBridgeInfo();
  const {
    state: { bridgePageInfo },
  } = useCustomRecoilState();

  useEffect(() => {
    // if (bridgePageInfo) return;
    fetchBridgeInfo();
  }, []);

  return (
    <div className="bg-white w-full h-full touch-none overscroll-contain overflow-hidden">
      {!bridgePageInfo && <Loading />}
      <Header />
      <div className="h-[calc(100vh-50px)] overflow-y-scroll overflow-x-hidden">
        <BridgeOverview />
        <CarouselSection />
        <Notification />
      </div>
    </div>
  );
}

export default DefaultPage;
