import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorModalProvider } from 'context';
import { useCustomRecoilState, useQueryParams } from 'hooks';

export const QueryParamWrapper = () => {
  const {
    state: { platform, mediaKey },
    setState: {
      setIsTest,
      setPlatform,
      setMediaKey,
      setIsLandscape,
      setIsViewMode,
      setIsNewInAppBrowser,
    },
  } = useCustomRecoilState();

  const {
    mediakey: _mediakey,
    isTestState,
    appKey,
    isNewInAppBrowser,
    platform: _platform,
    isLandScape,
    isViewType,
  } = useQueryParams();

  useEffect(() => {
    if (_platform) setPlatform(_platform);
    if (_mediakey || appKey) setMediaKey(parseInt(_mediakey || appKey));
    if (isTestState) setIsTest(isTestState === 'true');
    if (isLandScape) setIsLandscape(isLandScape);
    if (isViewType) setIsViewMode(isViewType);
    if (isNewInAppBrowser) setIsNewInAppBrowser(isNewInAppBrowser);
  }, [_mediakey, _platform]);

  if (!platform || !mediaKey) return null;

  return (
    <ErrorModalProvider>
      <Outlet />
    </ErrorModalProvider>
  );
};

export default QueryParamWrapper;
