import { Link } from 'react-router-dom';

import arrowBack from 'assets/arrow_back.svg';
import help from 'assets/help.svg';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export function Header() {
  const {
    state: { custom },
  } = useCustomRecoilState();

  const { closeOfferwall } = useSDKInterface();

  return (
    <div className="h-[44px] flex w-[100vw] overflow-hidden items-center justify-between px-[15px] py-[10px] bg-white z-20 tracking-[-0.5px]">
      <div
        className="flex flex-row gap-[6px] truncate"
        onClick={closeOfferwall}
        role="presentation"
      >
        <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
        <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">
          {custom.offerwallTitle}
        </div>
      </div>

      <Link to={'/cs'} className="flex-shrink-0">
        <img src={help} className="w-[24px] h-[24px]" alt="더보기" role="presentation" />
      </Link>
    </div>
  );
}

export default Header;
