import { useState } from 'react';

import { ChoiceOfferPopupWrapper } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { isToday, numberWithCommas } from 'utils';

export const ChoiceOfferPopup = () => {
  const { handleClickCampaign } = useClickCampaign();
  const [isClosed, setIsClosed] = useState(false);

  const displayTime = localStorage.getItem('lastChoiceOfferDisplayDate');

  const [lastChoiceOfferDisplayTime, setLastChoiceOfferDisplayTime] = useState(displayTime);
  const {
    state: { choiceOfferCampaign, custom, currency },
  } = useCustomRecoilState();

  // 현재 날짜가 주어진 날짜와 동일한지 확인하는 함수
  const onClosePopup = () => {
    const currentDate = new Date();
    setLastChoiceOfferDisplayTime(currentDate.toISOString());
    localStorage.setItem('lastChoiceOfferDisplayDate', currentDate.toISOString());
  };

  const showPopup =
    (!lastChoiceOfferDisplayTime && !!choiceOfferCampaign) ||
    (!isToday(new Date(lastChoiceOfferDisplayTime)) && !!choiceOfferCampaign);

  if (!showPopup || isClosed) return null;

  return (
    <div
      role="presentation"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsClosed(true);
        }
      }}
      className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute bottom-0 left-0 overflow-hidden px-[30px] flex justify-center items-center"
    >
      <div className="bg-white w-full absolute bottom-0 rounded-t-[10px] flex flex-col">
        <img
          src={choiceOfferCampaign.FeedMainImgURL}
          alt="초이스오퍼 이미지"
          className="rounded-t-[10px]"
        />
        <div className="p-[16px] flex flex-col gap-[16px]">
          <div className="flex gap-[8px]">
            <img
              src={choiceOfferCampaign?.IconImgURL || ''}
              alt="초이스오퍼 아이콘 이미지"
              className="w-[52px] h-[52px] rounded-[8px] top-[3px] left-[3px] relative"
            />
            <ChoiceOfferPopupWrapper />

            <div className="flex flex-col justify-center gap-[2px] pl-8">
              <span className="text-[16px] text-[#212529] leading-[20.8px] font-medium">
                {choiceOfferCampaign?.FeedTitle || ''}
              </span>
              <span className="text-[14px] text-[#808C99] leading-[16.8px]">
                {choiceOfferCampaign?.FeedDesc || ''}
              </span>
            </div>
          </div>
          <div className="flex gap-[10px] font-semibold" role="presentation">
            <button
              onClick={() => setIsClosed(true)}
              className="flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
            >
              닫기
            </button>
            <button
              style={{
                backgroundColor: custom.primaryColor,
              }}
              className={`whitespace-pre w-full flex justify-center items-center h-[46px] rounded-[8px] text-white`}
              onClick={() => {
                setIsClosed(true);
                onClosePopup();
                handleClickCampaign(choiceOfferCampaign);
              }}
            >
              {`${
                choiceOfferCampaign?.RewardDetail?.Quantity
                  ? `${numberWithCommas(choiceOfferCampaign?.RewardDetail?.Quantity)} ${currency}`
                  : typeof choiceOfferCampaign?.Reward === 'string'
                  ? `${numberWithCommas(choiceOfferCampaign?.Reward)}`
                  : `${choiceOfferCampaign?.Reward || 0} ${currency}`
              } 적립받기`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceOfferPopup;
