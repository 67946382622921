import { useCustomRecoilState } from 'hooks';

export const ChoiceOfferPopupWrapper = ({ size = 'large' }) => {
  const {
    state: { custom },
  } = useCustomRecoilState();

  const primaryColor = custom?.primaryColor;
  const isCustomColor = primaryColor !== '#2685F4';

  const customizedColorStyle = isCustomColor ? (
    <stop stopColor={primaryColor} />
  ) : (
    <>
      <stop stopColor="#5D34FF" />
      <stop offset="1" stopColor="#2685F4" />
    </>
  );

  if (size === 'large')
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute"
      >
        <rect
          x="1"
          y="1"
          width="56"
          height="56"
          rx="9"
          stroke="url(#paint0_linear_418_1742)"
          strokeWidth="2"
        />
        <circle cx="51" cy="51" r="8.5" fill="url(#paint1_linear_418_1742)" stroke="white" />
        <path
          d="M50.6413 46.7267C50.7566 46.4932 50.8142 46.3765 50.8924 46.3392C50.9605 46.3067 51.0395 46.3067 51.1076 46.3392C51.1858 46.3765 51.2434 46.4932 51.3587 46.7267L52.452 48.9416C52.4861 49.0106 52.5031 49.045 52.5279 49.0718C52.5499 49.0955 52.5763 49.1147 52.6057 49.1283C52.6388 49.1437 52.6768 49.1493 52.7529 49.1604L55.1985 49.5179C55.456 49.5555 55.5848 49.5743 55.6444 49.6372C55.6962 49.6919 55.7206 49.7671 55.7108 49.8419C55.6994 49.9278 55.6062 50.0186 55.4197 50.2002L53.6507 51.9232C53.5956 51.9769 53.568 52.0038 53.5502 52.0357C53.5345 52.064 53.5244 52.0951 53.5205 52.1273C53.5161 52.1636 53.5226 52.2015 53.5356 52.2774L53.953 54.7111C53.997 54.9677 54.019 55.0961 53.9776 55.1722C53.9417 55.2385 53.8777 55.285 53.8035 55.2987C53.7183 55.3145 53.6031 55.2539 53.3726 55.1327L51.1862 53.9829C51.1181 53.9471 51.084 53.9292 51.0481 53.9221C51.0163 53.9159 50.9837 53.9159 50.9519 53.9221C50.916 53.9292 50.882 53.9471 50.8138 53.9829L48.6275 55.1327C48.397 55.2539 48.2817 55.3145 48.1965 55.2987C48.1223 55.285 48.0584 55.2385 48.0224 55.1722C47.981 55.0961 48.003 54.9677 48.047 54.7111L48.4644 52.2774C48.4775 52.2015 48.484 52.1636 48.4796 52.1273C48.4757 52.0951 48.4656 52.064 48.4498 52.0357C48.432 52.0038 48.4044 51.9769 48.3493 51.9232L46.5803 50.2002C46.3938 50.0186 46.3006 49.9278 46.2893 49.8419C46.2794 49.7671 46.3038 49.6919 46.3556 49.6372C46.4152 49.5743 46.544 49.5555 46.8015 49.5179L49.2472 49.1604C49.3232 49.1493 49.3612 49.1437 49.3944 49.1283C49.4237 49.1147 49.4501 49.0955 49.4721 49.0718C49.497 49.045 49.514 49.0106 49.548 48.9416L50.6413 46.7267Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_418_1742"
            x1="0"
            y1="0"
            x2="58"
            y2="58"
            gradientUnits="userSpaceOnUse"
          >
            {customizedColorStyle}
          </linearGradient>
          <linearGradient
            id="paint1_linear_418_1742"
            x1="42"
            y1="42"
            x2="60"
            y2="60"
            gradientUnits="userSpaceOnUse"
          >
            {customizedColorStyle}
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute"
    >
      <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" stroke="url(#paint0_linear_418_1742)" />
      <circle cx="39" cy="39" r="6.5" fill="url(#paint1_linear_418_1742)" stroke="white" />
      <path
        d="M38.7608 36.1511C38.8377 35.9955 38.8761 35.9176 38.9282 35.8928C38.9736 35.8711 39.0263 35.8711 39.0717 35.8928C39.1238 35.9176 39.1623 35.9955 39.2391 36.1511L39.968 37.6278C39.9907 37.6737 40.002 37.6967 40.0186 37.7145C40.0332 37.7303 40.0508 37.7431 40.0704 37.7522C40.0925 37.7625 40.1178 37.7662 40.1685 37.7736L41.799 38.0119C41.9706 38.037 42.0565 38.0495 42.0962 38.0915C42.1308 38.128 42.147 38.1781 42.1405 38.2279C42.1329 38.2852 42.0707 38.3457 41.9465 38.4668L40.7671 39.6155C40.7303 39.6513 40.712 39.6692 40.7001 39.6905C40.6896 39.7093 40.6829 39.7301 40.6803 39.7515C40.6773 39.7757 40.6817 39.801 40.6903 39.8516L40.9686 41.474C40.998 41.6452 41.0126 41.7307 40.9851 41.7815C40.9611 41.8257 40.9184 41.8567 40.869 41.8658C40.8122 41.8764 40.7353 41.8359 40.5817 41.7551L39.1241 40.9886C39.0787 40.9647 39.056 40.9528 39.032 40.9481C39.0109 40.9439 38.9891 40.9439 38.9679 40.9481C38.944 40.9528 38.9213 40.9647 38.8758 40.9886L37.4183 41.7551C37.2646 41.8359 37.1878 41.8764 37.1309 41.8658C37.0815 41.8567 37.0389 41.8257 37.0149 41.7815C36.9873 41.7307 37.002 41.6452 37.0313 41.474L37.3096 39.8516C37.3183 39.801 37.3226 39.7757 37.3197 39.7515C37.3171 39.7301 37.3103 39.7093 37.2998 39.6905C37.288 39.6692 37.2696 39.6513 37.2328 39.6155L36.0535 38.4668C35.9292 38.3457 35.867 38.2852 35.8595 38.2279C35.8529 38.1781 35.8691 38.128 35.9037 38.0915C35.9434 38.0495 36.0293 38.037 36.201 38.0119L37.8314 37.7736C37.8821 37.7662 37.9075 37.7625 37.9295 37.7522C37.9491 37.7431 37.9667 37.7303 37.9814 37.7145C37.9979 37.6967 38.0093 37.6737 38.032 37.6278L38.7608 36.1511Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_418_1742"
          x1="0"
          y1="0"
          x2="44"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          {customizedColorStyle}
        </linearGradient>
        <linearGradient
          id="paint1_linear_418_1742"
          x1="32"
          y1="32"
          x2="46"
          y2="46"
          gradientUnits="userSpaceOnUse"
        >
          {customizedColorStyle}
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChoiceOfferPopupWrapper;
