import { Outlet } from 'react-router-dom';

import { useEventListener } from 'hooks';

export const EventListenerWrapper = () => {
  useEventListener();

  return <Outlet />;
};

export default EventListenerWrapper;
