import { CAMPAIGN_TYPES } from 'constants';

import RVIcon from 'assets/rv-icon.png';
import { RcpcAd } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { checkCampaignType, numberWithCommas } from 'utils';

export const CampaignRow = (props) => {
  const { data, index, style } = props;
  const campaign = data?.[index];

  const isRV = checkCampaignType(CAMPAIGN_TYPES.RV, campaign?.Key);
  const isRCPC = checkCampaignType(CAMPAIGN_TYPES.RCPC, campaign?.Key);

  return (
    <div style={style} key={index}>
      {isRCPC ? (
        <RcpcAd campaign={campaign} />
      ) : isRV ? (
        <RVCampaign campaign={campaign} />
      ) : (
        <DefaultCampaign campaign={campaign} style={style} />
      )}
    </div>
  );
};

/**RVCampaign */
const RVCampaign = ({ campaign }) => {
  const {
    state: { currency },
  } = useCustomRecoilState();

  const { handleClickRV } = useClickCampaign();

  return (
    <div
      className="px-20 my-16 flex justify-between items-center w-full h-full"
      role="presentation"
      onClick={() => handleClickRV(campaign)}
    >
      <div className="flex w-full gap-16">
        <img className="rounded-full h-64 max-w-64" alt="비디오 광고" src={RVIcon} />

        <div className="flex flex-col w-full gap-2 whitespace-nowrap overflow-hidden text-ellipsis py-8 justify-center">
          <div className="text-[16px] leading-[22px] text-[#0C1120] font-normal truncate">
            영상 보고 포인트 적립하기
          </div>

          <div className="text-[15px] leading-[24px] text-[#2D91EF] font-semibold max-w-[100px]">
            {`${campaign?.Point} ${currency} 적립하기`}
          </div>
        </div>
      </div>
    </div>
  );
};

/**Default Campaign */
const DefaultCampaign = ({ campaign }) => {
  const {
    state: { installedCampaignList, currency = 'P' },
  } = useCustomRecoilState();
  const { handleClickCampaign } = useClickCampaign();

  return (
    <div
      className="px-20 py-16 flex justify-between items-center w-full h-full"
      role="presentation"
      onClick={() => handleClickCampaign(campaign)}
    >
      <div className="flex w-full gap-16">
        {campaign?.IconImgURL || campaign?.IconUrl ? (
          <img
            className="rounded-full h-64 w-64"
            alt={campaign.Title}
            src={campaign.IconImgURL || campaign.IconUrl}
          />
        ) : (
          <div className="rounded-full h-64 w-64 bg-gray-300 inline-flex" />
        )}
        <div className="flex flex-col w-full gap-2 whitespace-nowrap overflow-hidden text-ellipsis py-8 justify-center">
          <div className="text-[16px] leading-[22px] text-[#0C1120] font-normal truncate">
            {campaign?.Title}
          </div>
          <div className="text-[15px] leading-[24px] text-[#2D91EF] font-semibold max-w-[100px]">
            {installedCampaignList?.includes(campaign.Key) ? (
              <span className="text-nowrap">설치 확인</span>
            ) : (
              <span className="truncate">
                {campaign?.RewardDetail
                  ? `${numberWithCommas(campaign?.RewardDetail?.Quantity)} ${
                      campaign?.RewardDetail?.Unit
                    } 적립하기`
                  : `${
                      typeof campaign?.Reward === 'string'
                        ? campaign?.Reward + ' 적립하기'
                        : `${numberWithCommas(campaign?.Reward)} ${currency} 적립하기`
                    }`}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
