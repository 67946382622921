import { useCustomRecoilState } from 'hooks';

export const SubTabSection = () => {
  const {
    state: {
      campaignList,
      selectedTab: { selectedTabId, selectedSubTabData },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  const selectedSubTabInfo =
    campaignList?.find((tab) => tab.TypeNo === selectedTabId)?.SubTabInfo || [];

  if (selectedSubTabInfo?.length === 0) return null;
  return (
    <div className="flex gap-[10px] text-[14px] tracking-[-0.5px] py-[10px] px-[15px] font-400 whitespace-nowrap w-full overflow-x-auto">
      {selectedSubTabInfo?.map((subTab) => (
        <div
          className={`py-[4px] px-[10px] rounded-[6px] ${
            subTab.TypeNo === selectedSubTabData[selectedTabId]
              ? 'text-white bg-gray-900'
              : 'text-gray-500 bg-[#F8F9FA]'
          }`}
          key={subTab.TypeNo}
          role="presentation"
          onClick={() => {
            setSelectedTab((prev) => {
              return {
                ...prev,
                selectedSubTabData: {
                  ...prev.selectedSubTabData,
                  [prev.selectedTabId]: subTab.TypeNo,
                },
              };
            });
          }}
        >
          {subTab.Name}
        </div>
      ))}
    </div>
  );
};
