import { useState } from 'react';

import { useCustomRecoilState } from 'hooks';

const campaignStatusList = [
  { name: '참여중', value: 'ONGOING' },
  { name: '참여완료', value: 'COMPLETED' },
];

export const CSHistory = () => {
  const {
    state: { custom, csInfo },
  } = useCustomRecoilState();
  const [campaignStatus, setCampaignStatus] = useState('ONGOING');

  const ongoingCampaigns = csInfo?.ongoingCampaigns;
  const completedCampaigns = csInfo?.completedCampaigns || [];

  return (
    <div className="flex flex-col gap-[20px] h-full">
      <div className="flex gap-[10px] text-[14px] tracking-[-0.5px] py-[12px] px-[16px] font-400 whitespace-nowrap w-full overflow-x-auto h-[50px]">
        {campaignStatusList?.map((campaign) => (
          <div
            key={campaign.value}
            role="presentation"
            onClick={() => {
              setCampaignStatus(campaign.value);
            }}
            className={`py-[4px] px-[10px] rounded-[6px] flex justify-center items-center ${
              campaign.value === campaignStatus
                ? 'text-white bg-gray-900'
                : 'text-gray-500 bg-[#F8F9FA]'
            }`}
          >
            {campaign.name}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-[20px] px-[16px] bg-white h-full overflow-scroll">
        {campaignStatus === 'COMPLETED' &&
          (completedCampaigns?.length ? (
            completedCampaigns?.map((campaign, idx) => (
              <div className="flex flex-col gap-[20px]" key={idx}>
                <div className="flex justify-between">
                  <div className="flex flex-col gap-[2px]">
                    <div className="font-[500] text-[15px] text-gray-900 h-[20px]">
                      {campaign.Title}
                    </div>
                    <div className="font-[400] h-[18px] leading-[17.94px] text-[13px] text-gray-500">
                      {`20${campaign?.Date}`.replaceAll('/', '-')}
                    </div>
                  </div>
                  <div
                    className="font-[600] text-[15px] leading-[20.4px]"
                    style={{
                      color: custom.primaryColor,
                    }}
                  >
                    +{campaign.Reward}
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#F9FAFA]" />
              </div>
            ))
          ) : (
            <div className="w-full h-full flex justify-center items-center text-gray-500 text-[14px]">
              <span>지급 내역이 없습니다.</span>
            </div>
          ))}
        {campaignStatus === 'ONGOING' &&
          (ongoingCampaigns?.MultiRewardCampaigns?.length ? (
            ongoingCampaigns?.MultiRewardCampaigns?.map((campaign, idx) => (
              <div
                className="flex gap-[12px] text-[15px] text-[#212529] pb-[20px] border-b border-[#F1F2F4]"
                key={idx}
              >
                <img
                  src={campaign.IconImgURL}
                  className="w-[64px] h-[64px] rounded-[8px]"
                  alt={campaign.Title}
                />
                <div>{campaign.Title}</div>
              </div>
            ))
          ) : (
            <div className="w-full h-full flex justify-center items-center text-gray-500 text-[14px]">
              <span>참여중인 캠페인이 없습니다.</span>
            </div>
          ))}
      </div>
    </div>
  );
};
