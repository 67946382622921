import { CUSTOM_MEDIA_TYPES } from 'constants';

import { useEffect, useState } from 'react';

import { useCustomRecoilState, useQueryParams } from 'hooks';
import { getCustomMediaType } from 'utils';

import { CashNotePage, CashNoteViewModePage, ZigZagPage, ZumPage } from './custom';
import { UnAuthorizedModal } from './default';
import DefaultPage from './default-page';
import LandScapePage from './landscape';
import ViewModePage from './viewmode';

export function MainPage() {
  const {
    state: { mediaKey, isViewMode, isLandscape },
  } = useCustomRecoilState();

  const { isAgreed } = useQueryParams();
  const [showMainPage, setShowMainPage] = useState(isAgreed);

  useEffect(() => {
    setShowMainPage(isAgreed);
  }, [isAgreed]);

  const PAGE_COMPONENTS = {
    [CUSTOM_MEDIA_TYPES.CASH_NOTE]: isViewMode ? CashNoteViewModePage : CashNotePage,
    [CUSTOM_MEDIA_TYPES.ZIG_ZAG]: isViewMode ? ViewModePage : ZigZagPage,
    [CUSTOM_MEDIA_TYPES.ZUM]: ZumPage,
  };

  const mediaType = getCustomMediaType(mediaKey);
  const CustomPage = PAGE_COMPONENTS[mediaType];

  if (!showMainPage)
    return (
      <UnAuthorizedModal
        isCashoNoteViewType={mediaType === CUSTOM_MEDIA_TYPES.CASH_NOTE && isViewMode}
      />
    );
  if (mediaType) return <CustomPage />;
  if (isViewMode) return <ViewModePage />;
  if (isLandscape) return <LandScapePage />;

  return <DefaultPage />;
}
