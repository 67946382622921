import { CAMPAIGN_TYPES } from 'constants';

import { useCustomRecoilState, useSDKInterface } from 'hooks';
import { checkCampaignType, convertCampaignParameter } from 'utils';

import { CampaignJoinButton } from './campaign-join-button';
import { CampaignOverview } from './campaign-overview';
import Header from './header';
import { MultiRewardInfo } from './multi-reward-info';
import { Notification } from './notification';
import { ParticipationStepMessageList } from './participation-step-message-list';

import { useDetailPage } from '../../default';

export function ZigZagPage() {
  useDetailPage();
  const {
    state: { selectedCampaign },
  } = useCustomRecoilState();
  const { joinCampaign } = useSDKInterface();

  if (!selectedCampaign) return null;

  const isMultiRewardType = checkCampaignType(
    CAMPAIGN_TYPES.MULTIREWARD,
    selectedCampaign.PartiTypeCode
  );

  return (
    <div
      style={{ height: window.innerHeight }}
      className="h-[100vh] absolute left-0 top-0 z-10 bg-[#F8F9FA] m-auto overscroll-y-none overflow-x-hidden scroll-smooth"
    >
      <Header title={selectedCampaign.FeedTitle} />
      <div className="bg-white border-b-[1px] border-solid border-[#F1F2F4] h-[calc(100%-44px)] overflow-y-scroll ">
        <div className="flex flex-col gap-[8px] pb-[24px] px-[16px]">
          <CampaignOverview campaign={selectedCampaign} />
          {isMultiRewardType && <MultiRewardInfo Campaign={selectedCampaign} />}
          <ParticipationStepMessageList
            participationStepMessageList={selectedCampaign?.ParticipationStepMessageList}
          />
        </div>
        <Notification />
        <CampaignJoinButton
          onConfirm={() => {
            joinCampaign([`"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`]);
          }}
        />
      </div>
    </div>
  );
}
