import { useEffect } from 'react';

import { useCustomRecoilState, useQueryParams } from 'hooks';

import { DefaultPage } from './default-page';

const mockData = {
  app_key: '1836641617',
  usn: 'APOfferwallTestUser1',
  adid: 'f2652b17-a94f-43c6-9267-5504ebf6e2aa',
  network: 'wifi',
  carrier: 'unknown',
  model: 'SM-S901N',
  manufacturer: 'samsung',
  os_version: '14',
  sdk_version: '9.1.2a',
  width: 1080,
  height: 2115,
  country: 'KR',
  language: 'ko',
  use_google_account: false,
  google_account_list: [],
  has_one_store: true,
  one_store_account_list: [],
  utc_offset: 9,
  market: '',
  android_opt_out_enabled: false,
  platform: 'android',
  sign_1: false,
  sign_2: false,
  sign_3: false,
  timestamp: 1734492426,
  build_target_version: 34,
  PID: '1836641617Bridge1',
};

function useMockData(isLocalhost) {
  const {
    setState: { setMediaKey, setParameter },
  } = useCustomRecoilState();

  // LocalStorage에서 객체를 불러오기
  function getMockData() {
    if (!isLocalhost) return null;
    if (window.location.hostname !== 'localhost') return null;

    return mockData;
  }

  useEffect(() => {
    const storedMockData = getMockData();

    if (!storedMockData) return;
    setParameter(storedMockData);
    setMediaKey(storedMockData.app_key);
  }, []);
}

export function BridgeTestPage() {
  const { isLocalhost } = useQueryParams();
  useMockData(isLocalhost);

  const {
    state: { parameter },
  } = useCustomRecoilState();

  if (!parameter) return null;

  // if (!isAgreed)
  //   return <UnAuthorizedModal showMainPage={isViewMode ? () => setShowMainPage(true) : null} />;

  return <DefaultPage />;
}

export default BridgeTestPage;
