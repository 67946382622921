import { Link, useNavigate } from 'react-router-dom';

import arrowBack from 'assets/arrow_back.svg';
import circleInfo from 'assets/circle-info.png';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export function Header() {
  const {
    state: { isNewInAppBrowser, selectedCampaign },
  } = useCustomRecoilState();

  const navigate = useNavigate();
  const { onClosedDetailPage, closeNewInAppBrowser } = useSDKInterface();

  const onClickBackButton = () => {
    onClosedDetailPage();

    if (isNewInAppBrowser) {
      closeNewInAppBrowser();
    }
    return navigate(-1);
  };

  return (
    <div className="h-[44px] top-0 flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20 tracking-[-0.5px]">
      <div className="flex flex-row gap-[6px] truncate">
        <img
          width={24}
          height={24}
          src={arrowBack}
          alt="뒤로가기"
          onClick={onClickBackButton}
          role="presentation"
        />
        <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">
          {selectedCampaign?.FeedTitle || ''}
        </div>
      </div>

      <Link to={'/cs'} className="flex-shrink-0">
        <img
          className="w-[24px] h-[24px]"
          width={24}
          height={24}
          src={circleInfo}
          alt="더보기"
          role="presentation"
        />
      </Link>
    </div>
  );
}

export default Header;
