import { useLocation } from 'react-router-dom';

import { ReactComponent as Check } from 'assets/check.svg';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export const SelectedCampaignBottomSheet = ({
  setShowBottomModal,
  selectedCampaign,
  setSelectedCampaign,
  CSCampaigns,
}) => {
  const {
    state: { custom, isTest, parameter },
  } = useCustomRecoilState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isTestFromQS = queryParams.get('isTest');

  const ISTEST = isTestFromQS ? isTestFromQS === 'true' : isTest;

  const { openWebBrowser } = useSDKInterface();

  const disabled = parameter?.adid === '00000000-0000-0000-0000-000000000000';

  return (
    <div
      className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden"
      role="presentation"
      onClick={(e) => {
        if (e?.target === e?.currentTarget) {
          setShowBottomModal(false);
        }
      }}
    >
      <div className="p-[20px] absolute bottom-0 rounded-t-[10px] bg-white z-30 w-full max-h-[calc(100vh-50px)] flex flex-col">
        <div className="text-[18px] pb-[18px] leading-[25.2px] text-gray-900 flex w-full items-center justify-center">
          캠페인 선택
        </div>
        <div className="flex flex-col text-[16px] leading-[22.4px] gap-[16px] h-full overflow-scroll">
          {CSCampaigns?.length &&
            CSCampaigns?.map((campaign, idx) => {
              const isSelected = campaign.Auth === selectedCampaign;
              return (
                <div className="flex justify-between" key={idx}>
                  <div
                    className={`${isSelected ? 'text-gray-900' : 'text-gray-500'}`}
                    role="presentation"
                    onClick={() => {
                      setShowBottomModal(false);
                      if (campaign.Auth !== 'ETC') {
                        if (disabled) return;
                        const encodedTitle = campaign.Title
                          ? encodeURIComponent(campaign.Title)
                          : '';
                        return openWebBrowser([
                          `https://offerwall.adpopcorn.com/cs-request?auth=${campaign.Auth}&campaign=${encodedTitle}&isTest=${ISTEST}&primaryColor=${custom.primaryColor}` ||
                            '',
                        ]);
                      }
                      setSelectedCampaign(campaign.Auth);
                    }}
                  >
                    {campaign.Title}
                  </div>
                  {isSelected && (
                    <Check
                      width={20}
                      height={20}
                      stroke={custom.primaryColor}
                      className="relative right-0"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
