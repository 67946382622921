import { MAIN_TAB_TYPES } from 'constants';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import check from 'assets/zig-zag/check.png';
import searchOrder from 'assets/zig-zag/search-order.png';
import { RVAd } from 'components';
import { useErrorModal } from 'context';
import {
  useClickCampaign,
  useCustomRecoilState,
  useFetchCampaignList,
  useFetchCPMCampaign,
  useSDKInterface,
} from 'hooks';
import { numberWithCommas } from 'utils';

import { BottomSheet } from './bottom-sheet';
import { CampaignRow } from './campaign-row';

const options = [
  { label: '리워드 높은순', value: '리워드 높은순' },
  { label: '리워드 낮은순', value: '리워드 낮은순' },
  { label: '추천순', value: '추천순' },
];

export function CampaignList() {
  const { openCustomModal } = useErrorModal();
  const fetchCampaignList = useFetchCampaignList();
  const fetchCPMCampaignList = useFetchCPMCampaign();
  const { handleClickCampaign } = useClickCampaign();
  const {
    state: {
      custom,
      campaignList,
      cpmCampaignList,
      tabList,
      selectedTab,
      blockedCampaigns,
      rvInfoList,
      parameter,
      isNoAd,
      temporalBlockedCampaigns,
      choiceOfferCampaign,
      totalReward,
      currency,
    },
  } = useCustomRecoilState();
  const { selectedTabId, selectedSubTabData } = selectedTab;
  const { closeOfferwall } = useSDKInterface();
  const navigate = useNavigate();

  const listRef = useRef(null);
  const [showChoiceOffer, setShowChoiceOffer] = useState(false);

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  const [selectedOption, setSelectedOption] = useState('추천순');
  const [showOptionSheet, setShowOptionSheet] = useState(false);

  useEffect(() => {
    if (!parameter) return;
    if (campaignList?.length) return;

    fetchCampaignList();
  }, []);

  useEffect(() => {
    if (!parameter) return;
    if (selectedTabId !== MAIN_TAB_TYPES['간편적립']) return;
    if (cpmCampaignList?.length > 0) return;

    fetchCPMCampaignList();
  }, [selectedTabId]);

  const CPMTabInfo = tabList?.filter((tab) => tab.IncludeParticipationNo.includes('43'))?.[0];

  const handleScroll = ({ scrollOffset }) => {
    if (selectedTabId === MAIN_TAB_TYPES['참여적립']) {
      setShowChoiceOffer(scrollOffset > 160);
    }
  };
  const selectedTabInfo = campaignList?.find((tab) => tab.TypeNo === selectedTabId);

  const selectedCampaigns =
    selectedTabId === CPMTabInfo?.TypeNo
      ? cpmCampaignList
      : selectedTabInfo?.campaigns ||
        selectedTabInfo?.SubTabInfo?.find(
          (subTab) => subTab.TypeNo === selectedSubTabData[selectedTabId]
        )?.campaigns ||
        [];

  const getFilteredSelectedCampaigns = () => {
    const rvListAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 0)?.[0];
    let filteredSelectedCampaigns = (selectedCampaigns || [])
      ?.filter((el) => !Object.keys(blockedCampaigns).includes(el?.Id?.toString()))
      ?.filter(
        (el) =>
          !temporalBlockedCampaigns.some((blockedCampaign) => {
            return (
              blockedCampaign.key ===
              (blockedCampaign.type === 'campaign' ? el?.Key?.toString() : el?.Id?.toString())
            );
          })
      );

    // 정렬 로직 추가
    filteredSelectedCampaigns = filteredSelectedCampaigns.sort((a, b) => {
      const aValue = a?.RewardDetail?.Quantity || a.Reward || 0;
      const bValue = b?.RewardDetail?.Quantity || b.Reward || 0;

      if (selectedOption === '리워드 높은순') {
        return bValue - aValue; // 내림차순 정렬
      } else if (selectedOption === '리워드 낮은순') {
        return aValue - bValue; // 오름차순 정렬
      } else {
        return 0; // 추천순, 정렬하지 않음
      }
    });

    if (rvListAd && rvListAd?.IsAvailable) {
      const index = selectedTabId === 2 ? rvListAd.DisplayPriority - 1 : 0;
      filteredSelectedCampaigns?.splice(index, 0, rvListAd);
    }

    return filteredSelectedCampaigns;
  };

  const selectedCampaignList = getFilteredSelectedCampaigns();

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.resetAfterIndex(0);
    listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
  }, [selectedCampaignList]);

  const hasRVFloatingAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 1)?.[0];

  return (
    <div className="flex flex-col w-full h-full overflow-hidden overscroll-none">
      {hasRVFloatingAd && <RVAd />}
      {choiceOfferCampaign && showChoiceOffer && selectedTab?.selectedTabId === 2 && (
        <div
          className="flex left-0 p-[15px] absolute w-full shadow-card bg-white items-center justify-between z-20"
          role="presentation"
          onClick={() => handleClickCampaign(choiceOfferCampaign)}
        >
          <div className="flex gap-[8px]">
            <img
              src={choiceOfferCampaign?.IconImgURL || ''}
              alt="초이스오퍼 아이콘 이미지"
              className="w-[40px] h-[40px] rounded-[6px]"
            />
            <div className="flex flex-col">
              <span className="text-[14px] text-[#212529] leading-[18.2px]">
                {choiceOfferCampaign?.FeedTitle || ''}
              </span>
              <span className="text-[13px] text-[#808C99] leading-[15.6px]">
                {choiceOfferCampaign?.FeedDesc || ''}
              </span>
            </div>
          </div>
          <div
            style={{
              backgroundColor: custom.primaryColor,
            }}
            className={`h-[28px] py-[4px] px-[11px] text-[14px] text-white leading-[19.6px] rounded-[16px]`}
          >
            {choiceOfferCampaign?.Reward}
          </div>
        </div>
      )}
      <div className="flex justify-between px-16 py-16 border-b border-b-zigzagGray-30 h-[64px]">
        <div className="flex gap-4 text-15 relative font-semibold justify-center items-center">
          <span className="text-zigzagGray-400">혜택 포인트</span>
          <span className="text-[#2F3337]">
            {numberWithCommas(totalReward)}
            {currency}
          </span>
        </div>
        <button
          className="flex justify-center items-center text-[#4A5056]"
          onClick={() => {
            setShowOptionSheet(true);
          }}
        >
          <img src={searchOrder} alt="필터" className="size-16" />
          <span className="font-bold text-13">{selectedOption}</span>
        </button>
      </div>
      {showOptionSheet && (
        <BottomSheet
          onClose={(e) => {
            if (e.target === e.currentTarget) {
              setShowOptionSheet(false);
            }
          }}
          content={
            <div className="text-16 font-semibold text-zigzagGray-900">
              {options.map((option) => (
                <button
                  key={option.value}
                  className="p-20 w-full flex justify-between border-b border-b-zigzagGray-100"
                  onClick={() => {
                    setSelectedOption(option.value);
                    setShowOptionSheet(false);
                  }}
                >
                  <span>{option.label}</span>
                  {selectedOption === option.value && (
                    <img src={check} alt="체크 아이콘" className="size-16" />
                  )}
                </button>
              ))}
            </div>
          }
        />
      )}
      {showTimeoutModal && (
        <BottomSheet
          onClose={() => setShowTimeoutModal(false)}
          content={
            <div className="flex flex-col w-[100vw]">
              <div className="flex flex-col gap-8 py-32 px-16 justify-center items-center w-full">
                <span className="font-bold text-20 text-zigzagGray-950">잠시만 기다려 주세요</span>
                <span className="font-medium text-16 text-zigzagGray-600 flex justify-center items-center text-center">
                  서버로 부터 응답이 늦어지고 있어요.
                  <br />
                  잠시후 다시 확인해 주세요.
                </span>
              </div>
              <button
                onClick={() => {
                  setShowTimeoutModal(false);
                  closeOfferwall();
                }}
                className="mx-16 mt-8 mb-16 py-16 bg-zigzagGray-950 text-15 font-semibold text-zigzagGray-50 flex justify-center items-center rounded-full"
              >
                확인
              </button>
            </div>
          }
        />
      )}

      <div className="grow">
        {selectedCampaignList?.length === 0 ? (
          <div className="w-full h-full flex justify-center items-center text-gray-500 text-[14px]">
            <span>진행중인 미션이 없습니다.</span>
          </div>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={listRef}
                onScroll={handleScroll}
                height={height}
                width={width}
                itemData={selectedCampaignList}
                itemSize={(idx) => {
                  return selectedCampaignList?.[idx]?.Key === '958189455' ? 120 : 88;
                }}
                itemCount={selectedCampaignList?.length}
              >
                {CampaignRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
}

export default CampaignList;
