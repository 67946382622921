import { useState } from 'react';

import { ReactComponent as CheckedBox } from 'assets/checked_box.svg';
import { useCustomRecoilState } from 'hooks';

import { useUnauthorizedModal } from './use-unauthorized-modal';

export const UnAuthorizedModal = ({ isCashoNoteViewType }) => {
  const CASHNOTE_HEIGHT = 673;

  const [showUnauthofizedModal, setShowUnauthofizedModal] = useState(true);
  const { onClickAgree, onClickDisAgree } = useUnauthorizedModal();

  const [isChecked, setIsChecked] = useState(false);
  const {
    state: { custom },
  } = useCustomRecoilState();

  if (!showUnauthofizedModal) return null;

  return (
    <div
      className="w-full bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden px-[30px] flex justify-center items-center"
      style={{ height: isCashoNoteViewType ? `${CASHNOTE_HEIGHT}px` : '100vh' }}
    >
      <div className="w-[420px] rounded-[14px] bg-white">
        <div className="font-semibold text-gray-900 text-[18px] leading-[27px] py-[16px] flex justify-center border-b border-[#F1F2F4]">
          개인정보 수집 동의
        </div>
        <div className="p-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
          <div>
            애드팝콘은 광고 참여 정보 확인 및 리워드 제공을 위하여 아래의 정보를 수집 및 이용하고
            있습니다.
          </div>
          <div>
            <div>수집하는 개인정보의 항목</div>
            <div className="text-gray-900">Google 광고 ID</div>
          </div>
          <div>
            <div>개인정보 수집 동의 거부</div>
            <div className="text-gray-900">
              개인 정보 수집 동의를 거부할 수 있으며, 미 동의 시 서비스 이용이 제한됩니다.
            </div>
          </div>
          <div className="flex py-[14px] gap-[8px]">
            <div className="w-[20px] h-[20px]">
              <CheckedBox
                onClick={() => setIsChecked(!isChecked)}
                width={20}
                height={20}
                fill={isChecked ? custom.primaryColor : '#D9D9D9'}
              />
            </div>
            <div className="text-[15px] leading-[21px] text-gray-900">
              개인정보 이용 및 수집에 동의합니다.
            </div>
          </div>
          <div className="flex gap-[10px]">
            <button
              className="font-semibold flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
              onClick={() => {
                setShowUnauthofizedModal(false);
                onClickDisAgree();
              }}
            >
              거부
            </button>
            <button
              onClick={onClickAgree}
              disabled={!isChecked}
              style={{
                backgroundColor: isChecked ? custom.primaryColor : '#B8BFC6',
              }}
              className={`font-semibold flex justify-center items-center w-[50%] h-[46px] rounded-[8px] text-white`}
            >
              동의
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
