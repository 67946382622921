import { CUSTOM_MEDIA_TYPES } from 'constants';

import { useCustomRecoilState } from 'hooks';
import { getCustomMediaType } from 'utils';

import { CashNotePage, ZigZagPage, ZumPage } from './custom';
import { DefaultPage } from './default-page';

export function DetailPage() {
  const {
    state: { mediaKey },
  } = useCustomRecoilState();

  const PAGE_COMPONENTS = {
    [CUSTOM_MEDIA_TYPES.CASH_NOTE]: CashNotePage,
    [CUSTOM_MEDIA_TYPES.ZIG_ZAG]: ZigZagPage,
    [CUSTOM_MEDIA_TYPES.ZUM]: ZumPage,
  };

  const mediaType = getCustomMediaType(mediaKey);
  const CustomPage = PAGE_COMPONENTS[mediaType];

  if (mediaType) return <CustomPage />;

  return <DefaultPage />;
}
