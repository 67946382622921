import { CAMPAIGN_TYPES, MAIN_TAB_TYPES } from 'constants';

import { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { FixedChoiceOffer, Footer, RVAd } from 'components';
import { useCustomRecoilState, useFetchCampaignList, useFetchCPMCampaign } from 'hooks';
import { checkCampaignType } from 'utils';

import { CampaignRow } from './campaign-row';
import { FilteringOptionSection } from './filtering-option-section';
import { NoCampaign } from './no-campaign';

export function CampaignList() {
  const {
    state: {
      campaignList,
      cpmCampaignList,
      selectedTab: { selectedTabId },
      selectedTabCampaigns,
      rvInfoList,
      parameter,
    },
  } = useCustomRecoilState();

  const fetchCampaignList = useFetchCampaignList();
  const fetchCPMCampaignList = useFetchCPMCampaign();

  const [showChoiceOffer, setShowChoiceOffer] = useState(false);

  const listRef = useRef(null);

  useEffect(() => {
    if (!parameter) return;
    if (campaignList?.length) return;

    fetchCampaignList();
  }, []);

  useEffect(() => {
    if (!parameter) return;
    if (selectedTabId !== MAIN_TAB_TYPES['간편적립']) return;
    if (cpmCampaignList?.length > 0) return;

    fetchCPMCampaignList();
  }, [selectedTabId]);

  const handleScroll = ({ scrollOffset }) => {
    if (selectedTabId === MAIN_TAB_TYPES['참여적립']) {
      setShowChoiceOffer(scrollOffset > 160);
    }
  };

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.resetAfterIndex(0);
    listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
  }, [selectedTabCampaigns]);

  const hasRVFloatingAd = rvInfoList?.find((rv) => rv.PlacementTypeNo === 1);

  return (
    <div className="px-[15px] flex flex-col w-full h-full overflow-hidden overscroll-none">
      {hasRVFloatingAd && <RVAd />}
      {showChoiceOffer && <FixedChoiceOffer />}
      <FilteringOptionSection />
      <div className="grow mb-[20px]">
        {selectedTabCampaigns.length === 0 ? (
          <NoCampaign />
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={listRef}
                onScroll={handleScroll}
                height={height}
                width={width}
                itemData={selectedTabCampaigns}
                itemSize={(idx) => {
                  return checkCampaignType(CAMPAIGN_TYPES.RCPC, selectedTabCampaigns?.[idx]?.Key)
                    ? 120
                    : (window.innerWidth - 30) * 0.52 + 80;
                }}
                itemCount={selectedTabCampaigns?.length}
              >
                {CampaignRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CampaignList;
