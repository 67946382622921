import expandMore from 'assets/expand_more.png';
import { useCustomRecoilState, useSDKInterface } from 'hooks';

export const CSFAQ = () => {
  const {
    state: { csInfo },
  } = useCustomRecoilState();
  const { openWebBrowser } = useSDKInterface();

  const faqList = [
    {
      Title: '문의 접수 방법(고객센터)',
      Link: 'https://adpopcorn.notion.site/FAQ-35edb7e2c4ff4311b2bcf616c8e1b022#b9dfd6560437424391087400c46f7b2a',
      Status: 1,
      Priority: 9997,
    },
    {
      Title: '광고 참여 보상 관련',
      Link: 'https://adpopcorn.notion.site/FAQ-35edb7e2c4ff4311b2bcf616c8e1b022#dfca40fc9cc94b53a51b3521ad9c5558',
      Status: 1,
      Priority: 9998,
    },
    {
      Title: '광고 참여 제한 관련',
      Link: 'https://adpopcorn.notion.site/FAQ-35edb7e2c4ff4311b2bcf616c8e1b022#4760f461db8f41c9bf2b5abe91c60be3',
      Status: 1,
      Priority: 9999,
    },
    {
      Title: '광고 미노출 문의',
      Link: 'https://adpopcorn.notion.site/FAQ-35edb7e2c4ff4311b2bcf616c8e1b022#2dc8698ca97c4beaac840a1e2daaee6b',
      Status: 1,
      Priority: 10000,
    },
  ];

  return (
    <>
      {(csInfo?.faqList || faqList)?.map((campaign, index) => (
        <div className="text-gray-900" key={index}>
          <div
            className="text-[16px] px-[15px] py-[22px] leading-[24px] flex justify-between"
            role="presentation"
            onClick={() => {
              openWebBrowser([campaign.Link || '']);
            }}
          >
            {campaign.Title}

            <img width={20} height={20} src={expandMore} alt="더보기" />
          </div>

          <div className="w-full h-[1px] bg-gray-100" />
        </div>
      ))}
    </>
  );
};
