import { MAIN_TAB_TYPES } from 'constants';

import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { Footer, RVAd } from 'components';
import { useErrorModal } from 'context';
import {
  useCustomRecoilState,
  useFetchCampaignList,
  useFetchCPMCampaign,
  useSDKInterface,
} from 'hooks';

import { Row } from './campaign-row-landscape';

import { FilteringOptionSection, NoCampaign } from '../default';

export function CampaignListLandscape() {
  const { openCustomModal } = useErrorModal();
  const {
    state: {
      campaignList,
      cpmCampaignList,
      selectedTab: { selectedTabId },
      selectedTabCampaigns,
      rvInfoList,
      parameter,
      isNoAd,
    },
  } = useCustomRecoilState();
  const { closeOfferwall } = useSDKInterface();
  // const [showChoiceOffer, setShowChoiceOffer] = useState(false);

  const navigate = useNavigate();

  const fetchCampaignList = useFetchCampaignList();
  const fetchCPMCampaignList = useFetchCPMCampaign();

  const listRef = useRef(null);

  useEffect(() => {
    if (!parameter) return;
    if (campaignList?.length) return;

    fetchCampaignList();
  }, [parameter]);

  useEffect(() => {
    if (!parameter) return;
    if (selectedTabId !== MAIN_TAB_TYPES['간편적립']) return;
    if (cpmCampaignList?.length > 0) return;

    fetchCPMCampaignList();
  }, [selectedTabId, parameter]);

  // const handleScroll = ({ scrollOffset }) => {
  //   if (selectedTabId === MAIN_TAB_TYPES['참여적립']) {
  //     setShowChoiceOffer(scrollOffset > 160);
  //   }
  // };

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.resetAfterIndex(0);
    listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
  }, [selectedTabCampaigns]);

  const hasRVFloatingAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 1)?.[0];

  return (
    <div className={`px-[15px] flex flex-col w-full h-full overflow-hidden overscroll-none`}>
      {hasRVFloatingAd && <RVAd />}
      {/* {showChoiceOffer && <FixedChoiceOffer />} */}
      <FilteringOptionSection />

      <div className="grow mb-[20px] overflow-x-hidden">
        {selectedTabCampaigns.length === 0 ? (
          <NoCampaign />
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={listRef}
                height={height}
                // onScroll={handleScroll}
                width={width}
                itemData={selectedTabCampaigns}
                itemSize={(idx) => {
                  return 110;
                }}
                itemCount={Math.ceil(selectedTabCampaigns?.length / 2)}
              >
                {Row}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CampaignListLandscape;
