import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRVInfoList } from 'apis';
import { useErrorModal } from 'context';
import { useClickCampaign, useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';
import { convertCampaignParameter, isToday } from 'utils';

const filterObjectByCondition = (obj, condition) => {
  const filteredObject = {};

  // 객체의 모든 키에 대해 순회
  for (const key in obj) {
    // 객체의 속성인지 확인하고, 조건을 만족하는지 확인
    if (Object.hasOwnProperty.call(obj, key) && condition(obj[key])) {
      filteredObject[key] = obj[key]; // 조건을 만족하는 속성을 새 객체에 추가
    }
  }

  return filteredObject;
};

export const useEventListener = () => {
  const navigate = useNavigate();
  const { openNewInAppBrowser } = useSDKInterface();
  const { isRewardVideoIntegrated } = useQueryParams();
  const {
    state: {
      selectedCampaign,
      isViewMode,
      installedCampaignList: installedCampaignKeys,
      mediaKey,
      parameter,
      platform,
      isTest,
      rvInfoList,
      blockedCampaigns,
    },

    setState: {
      setParameter,
      setBlockedCampaigns,
      setTemporalBlockedCampaigns,
      setInstalledCampaignList,
      setRvInfoList,
      setCompletedRcpcList,
    },
  } = useCustomRecoilState();
  const { openModal, openCustomModal, closeModal } = useErrorModal();
  const { checkOrJoinCampaign } = useClickCampaign();
  const { joinCampaign, showRewardVideo, executeAppAndReward } = useSDKInterface();

  useEffect(() => {
    const blockedCampaignListToday = localStorage.getItem('blockedCampaignList')
      ? filterObjectByCondition(JSON.parse(localStorage.getItem('blockedCampaignList')), isToday)
      : {};

    localStorage.setItem('blockedCampaignList', JSON.stringify(blockedCampaignListToday));
  }, []);

  /**actions */
  const handleCampaignCompleted = (newItem) => {
    if (!newItem) return;

    setInstalledCampaignList((prev) => {
      const updatedKeys = prev?.filter((el) => el !== newItem?.toString()) || [];
      localStorage.setItem('installedCampaigns', JSON.stringify(updatedKeys));
      return updatedKeys;
    });

    setTemporalBlockedCampaigns((prev) => [
      ...prev,
      { type: 'campaign', key: newItem?.toString() },
    ]);
  };

  const handleCpmCampaignCompleted = (newItem) => {
    if (!newItem) return;
    setTemporalBlockedCampaigns((prev) => [...prev, { type: 'content', key: newItem?.toString() }]);
  };

  const handleNewsWebtoonCompleted = (newItem) => {
    if (!newItem) return;
    const currentDate = new Date();
    const updatedList = { ...blockedCampaigns, [newItem]: currentDate.toISOString() };
    setBlockedCampaigns(updatedList);
    localStorage.setItem('blockedCampaignList', JSON.stringify(updatedList));
  };

  const handleShowDetailPage = () => {
    if ([6, 7, 23].includes(selectedCampaign.PartiTypeCode)) {
      const updatedList = selectedCampaign?.Key
        ? [...new Set([...installedCampaignKeys, selectedCampaign.Key])]
        : installedCampaignKeys;
      setInstalledCampaignList(updatedList);
      localStorage.setItem('installedCampaigns', JSON.stringify(updatedList));
    }

    const openURL = isTest
      ? 'https://stage.offerwall.adpopcorn.com'
      : 'https://offerwall.adpopcorn.com';
    if (isViewMode) {
      return openNewInAppBrowser([
        `${openURL}/SDK/detail?auth=${selectedCampaign?.Auth}&isTest=${isTest}&platform=${platform}&mediakey=${mediaKey}&isNewInAppBrowser=true`,
      ]);
    }
    navigate('/SDK/detail', { state: { campaign: selectedCampaign } });
  };

  const handleRewardVideoCompleted = async (rewardVideoPlacementId) => {
    if (!isRewardVideoIntegrated) return;

    const RVParams = {
      mediaKey,
      adid: parameter?.adid || null,
      idfa: parameter?.idfa || null,
      usn: parameter?.usn || null,
    };

    try {
      const rvInfoListData = await getRVInfoList(RVParams, isTest);
      const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];
      const updatedRv = rewardVideoInfo?.find(
        (newRv) => newRv?.IntegrationPlacementId === rewardVideoPlacementId
      );

      const updatedRVInfoList = rvInfoList?.map((rv) =>
        rv?.IntegrationPlacementId === rewardVideoPlacementId
          ? { ...rv, status: 'failed', ...updatedRv }
          : rv
      );
      setRvInfoList(updatedRVInfoList);
    } catch (e) {
      openModal(e.message || '');
    }
  };

  const handleRewardVideoLoaded = (rewardVideoPlacementId) => {
    const floatingRV = rvInfoList?.find(
      (item) =>
        item?.IntegrationPlacementId === rewardVideoPlacementId && item?.PlacementTypeNo === 1
    );

    if (floatingRV) {
      joinCampaign([`"${JSON.stringify(convertCampaignParameter(floatingRV))}"`]);
    }

    const newVal = rvInfoList?.map((rv) =>
      rv?.IntegrationPlacementId === rewardVideoPlacementId ? { ...rv, status: 'success' } : rv
    );

    setRvInfoList(newVal);
  };

  const handleRewardVideoLoadFailed = (rewardVideoPlacementId) => {
    const filteredList = rvInfoList?.filter(
      (rv) => rv?.IntegrationPlacementId !== rewardVideoPlacementId
    );
    setRvInfoList(filteredList);
  };

  const handleInstallFail = (message) => {
    openCustomModal({
      message: message || '',
      closeButtonText: '닫기',
      onClose: closeModal,
      confirmButtonText: '참여하기',
      onConfirm: () => {
        joinCampaign([`"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`]);
      },
    });
  };

  const showVideo = () => {
    showRewardVideo();
  };

  useEffect(() => {
    const nativeResponse = async (event) => {
      console.log(event);
      const eventResult = event?.detail?.data;
      const eventName = eventResult?.EventName;
      const eventBody = eventResult?.EventBody;
      const modalMessage = (eventResult?.ResultMessage || '')
        .replace(/<br\s*\/?>/gi, '\n') // <br> 또는 <br/> 태그를 \n으로 변환
        .replace(/<\/br>/gi, '\n'); // </br> 태그를 \n으로 변환

      switch (eventName) {
        case 'getParameter':
          return setParameter(eventBody ? JSON.parse(eventBody) : null);
        case 'refreshCampaignList':
          return window.location.reload();
        case 'rewardSuccess':
        case 'normalDialog':
          return openCustomModal({
            message: modalMessage,
            confirmButtonText: '닫기',
            onConfirm: closeModal,
          });
        case 'executeAppAndReward':
          openCustomModal({
            message: modalMessage,
            confirmButtonText: '확인',
            onConfirm: () => {
              executeAppAndReward();
              closeModal();
            },
          });
          break;
        case 'campaignCompleted':
          handleCampaignCompleted(eventResult.EventParam);
          break;
        case 'cpmCampaignCompleted':
          handleCpmCampaignCompleted(eventResult.EventParam);
          break;
        case 'newsCampaignCompleted':
        case 'webtoonCampaignCompleted':
          handleNewsWebtoonCompleted(eventResult.EventParam);
          break;
        case 'showDetailPage':
          if (eventResult.Result) handleShowDetailPage();
          break;
        case 'showRewardVideo':
          showVideo();
          break;
        case 'rcpcCampaignCompleted':
          setCompletedRcpcList((prev) => [...prev, eventResult.EventParam]);
          break;
        case 'rewardVideoCampaignCompleted':
          handleRewardVideoCompleted(eventResult.EventParam);
          break;
        case 'OnRewardVideoAdLoaded':
          handleRewardVideoLoaded(eventResult.EventParam);
          break;
        case 'OnRewardVideoAdLoadFailed':
          handleRewardVideoLoadFailed(eventResult.EventParam);
          break;
        case 'installFail':
          handleInstallFail(eventResult.ResultMessage);
          break;
        case 'checkCampaignPackageTargetList':
          if (eventResult.Result) checkOrJoinCampaign(selectedCampaign);
          break;
        default:
          break;
      }
    };

    window.addEventListener('NativeEvent', nativeResponse);

    return () => {
      window.removeEventListener('NativeEvent', nativeResponse);
    };
  }, [selectedCampaign, rvInfoList]);
};
