import { MAIN_TAB_TYPES } from 'constants';

import { useNavigate } from 'react-router-dom';

import CryptoJS from 'crypto-js';

import { completeCampaign, participateCampaign } from 'apis';
import { useErrorModal } from 'context';
import { useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';
import { convertCampaignParameter } from 'utils';

export const useClickCampaign = () => {
  const {
    state: { selectedTab, parameter, isTest },
    setState: { setBlockedCampaigns, setSelectedCampaign },
  } = useCustomRecoilState();

  const { selectedTabId } = selectedTab;
  const {
    logging,
    loadRewardVideo,
    checkCampaignPackageTargetList,
    joinCampaign,
    checkCampaign,
    openWebBrowser,
  } = useSDKInterface();
  const { isLocalhost } = useQueryParams();
  const navigate = useNavigate();

  const { openModal, closeModal } = useErrorModal();

  const handleClickCampaign = (campaign) => {
    const isNasMedia = campaign?.IntegrationTypeCode === 46;
    setSelectedCampaign(campaign);

    if (isLocalhost) {
      return navigate('/SDK/detail');
    }

    if (isNasMedia) return clickNasCampaign(campaign);
    else {
      LogImpressionDetailPage(campaign);
      checkInstalledPackageList(campaign);
      checkOrJoinCampaign(campaign);
    }
  };

  const LogImpressionDetailPage = (campaign) => {
    let eventName = 'impression_offerwall_detail_page';
    let eventParam = campaign?.Key || '';

    switch (selectedTabId) {
      case MAIN_TAB_TYPES['참여적립']:
        eventName = 'impression_offerwall_detail_page_participation';
        break;
      case MAIN_TAB_TYPES['쇼핑적립']:
        eventName = 'impression_offerwall_detail_page_shopping';
        break;
      case MAIN_TAB_TYPES['클릭적립']:
        break;
      case MAIN_TAB_TYPES['전체']:
        eventParam = '0';
        break;
      default:
        eventParam =
          selectedTab?.selectedSubTabData && selectedTab?.selectedTabId
            ? selectedTab?.selectedSubTabData[selectedTab?.selectedTabId]?.toString()
            : '';
    }

    logging([eventName, eventParam]);
  };

  const checkInstalledPackageList = (campaign) => {
    if (campaign?.TargetPackageName?.length || campaign?.DetargetPackageName?.length) {
      return checkCampaignPackageTargetList([
        campaign?.TargetPackageName || [],
        campaign?.DetargetPackageName || [],
      ]);
    }
  };

  const checkOrJoinCampaign = (campaign) => {
    const campaignParams = convertCampaignParameter(campaign);
    const partiNo = campaign?.PartiTypeCode;
    const joinCampaignType = [43, 46, 52, 53];

    if ([...joinCampaignType].includes(partiNo)) {
      return joinCampaign([`"${JSON.stringify(campaignParams)}"`]);
    }

    checkCampaign([`"${JSON.stringify(campaignParams)}"`]);
  };

  const clickNasCampaign = async (campaign) => {
    const rewardString = campaign?.Reward || null;
    const parsedReward =
      typeof rewardString === 'string' ? parseInt(rewardString, 10) : rewardString;

    const data = {
      IntegrationId: campaign?.IntegrationId || null,
      Id: campaign?.Id || null,
      Name: campaign?.Name || null,
    };

    // 객체의 값들을 배열로 변환한 후, join 메서드를 사용하여 콤마로 결합
    function combineValuesToString(obj) {
      // Check if any value in the object is null
      if (Object.values(obj).some((value) => value === null)) {
        return null;
      }

      // Join the values with a comma if none are null
      return Object.values(obj).join(',');
    }

    const customParam = combineValuesToString(data);
    if (!customParam) return openModal('참여에 필요한 필수정보가 없습니다.');

    const params = {
      participation_type_no: campaign?.PartiTypeCode || null,
      auth: campaign?.Auth || null,
      network: parameter?.network || null, // 참여적립
      app_key: parameter?.app_key || null, // 간편/뉴스/웹툰 적립
      integration_type_no: campaign?.IntegrationTypeCode || null, // 간편/뉴스/웹툰 적립
      usn: parameter?.usn || null, // 간편/뉴스/웹툰 적립
      adid: parameter?.adid || null, // 간편/뉴스/웹툰 적립
      idfa: parameter?.idfa || null, // 간편/뉴스/웹툰 적립
      point: parsedReward, // 간편/뉴스/웹툰 적립
      contents_id: null, // 뉴스/웹툰 적립 (뉴스, 웹툰 ID)
      custom_param: customParam,
    };

    // 특정 조건 체크
    try {
      const response = await participateCampaign(params, isTest);

      const auth = response?.Auth || null;
      const sign = CryptoJS.HmacMD5(auth, campaign?.Key)?.toString() || null;
      await completeCampaign(
        {
          participation_type_no: campaign?.PartiTypeCode || null,
          auth,
          sign,
          network: parameter?.network || null,
        },
        isTest
      );

      setBlockedCampaigns((prev) => [...prev, { type: 'content', key: campaign?.Id?.toString() }]);
      if (response?.RedirectURL) {
        openWebBrowser([response?.RedirectURL]);
      }
    } catch (error) {
      return openModal(error.message || '');
    }
  };

  const handleClickRV = (rvListAd) => {
    if (rvListAd?.status === 'loading') {
      return openModal('광고가 준비중이에요!\n1분 후 다시 참여해주세요.', closeModal);
    }

    if (rvListAd?.status === 'failed') {
      loadRewardVideo([
        rvListAd?.IntegrationAppKey || '',
        rvListAd?.IntegrationPlacementId || '',
        true,
      ]);
    }

    if (rvListAd?.status === 'success') {
      joinCampaign([`"${JSON.stringify(convertCampaignParameter(rvListAd))}"`]);
    }
  };

  return { handleClickCampaign, checkOrJoinCampaign, LogImpressionDetailPage, handleClickRV };
};
