import { useNavigate } from 'react-router-dom';

import { getBridgeCampaignInfo } from 'apis';
import { useErrorModal } from 'context';
import { useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';

export function useFetchBridgeInfo() {
  const {
    state: { parameter },
    setState: {
      setIsTest,
      setBridgePageInfo,
      setTotalReward,
      setCurrency,
      setTotalOfferwallRewardQuantity,
    },
  } = useCustomRecoilState();

  const {
    onOfferwallLoadSuccess,
    onOfferwallLoadFailed,
    setTotalReward: SetTotalReward,
    setRewardCurrency,
    closeOfferwall,
    isTestMode,
  } = useSDKInterface();

  const { PID } = useQueryParams();
  const { openCustomModal, openModal } = useErrorModal();
  const navigate = useNavigate();

  const fetchBridgeInfo = async () => {
    if (!PID || !parameter) return;
    try {
      const bridgeInfo = await getBridgeCampaignInfo({ ...parameter, PID });
      const totalReward = bridgeInfo?.TotalRewardQuantity;
      const totalOfferwallRewardQuantity = bridgeInfo?.TotalOfferwallRewardQuantity;
      const currency = bridgeInfo?.RewardCurrency;
      const isTest = bridgeInfo?.IsTest;
      const bridgeType = bridgeInfo?.BridgeType || 0;
      const bridgeStyleType = bridgeInfo?.StyleType || 0;
      const bridgeSections = bridgeInfo?.Sections || [];
      setBridgePageInfo({
        bridgeType,
        bridgeStyleType,
        bridgeSections,
      });
      onOfferwallLoadSuccess();

      setTotalReward(totalReward);
      setTotalOfferwallRewardQuantity(totalOfferwallRewardQuantity);
      SetTotalReward([totalOfferwallRewardQuantity]);

      setCurrency(currency);
      setRewardCurrency([currency]);

      setIsTest(isTest);
      isTestMode([isTest]);
    } catch (e) {
      setBridgePageInfo([]);
      onOfferwallLoadFailed();
      if (e?.code === 501) {
        return openModal(
          "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
        );
      } else if (e?.code === 980) {
        return openCustomModal({
          title: '확인',
          ResultMessage:
            '참여 가능한 광고가 없습니다. 자세한 안내를 보시려면 아래 이동 버튼을 눌러주세요.',
          left: {
            text: '닫기',
            func: () => {
              closeOfferwall();
            },
          },
          right: {
            text: '이동',
            func: () => {
              closeOfferwall();
              navigate('/cs');
            },
          },
        });
      } else openModal(e.message || '');
    }
  };
  return fetchBridgeInfo;
}
