import { CAMPAIGN_TYPES } from 'constants';

import RVIcon from 'assets/rv-icon.png';
import { RcpcAd } from 'components';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { checkCampaignType, convertHexToRgba, numberWithCommas } from 'utils';

export const RowItem = (props) => {
  const { campaign, index, style } = props;

  const isRV = checkCampaignType(CAMPAIGN_TYPES.RV, campaign?.Key);
  const isRCPC = checkCampaignType(CAMPAIGN_TYPES.RCPC, campaign?.Key);

  return (
    <div style={style} key={index}>
      {isRCPC ? (
        <RcpcAd campaign={campaign} />
      ) : isRV ? (
        <RVCampaign campaign={campaign} />
      ) : (
        <DefaultCampaign campaign={campaign} />
      )}
    </div>
  );
};

export const Row = (props) => {
  const { data, index, style } = props;

  const item1 = data?.[index * 2];
  const item2 = data?.[index * 2 + 1];

  const RowStyle = {
    ...style,
    width: 'calc(50% - 10px)',
    height: '88px',
    position: 'relative',
    boxShadow: '0px 4px 14px 0px #07182D0F',
    borderRadius: '10px',
  };

  return (
    <div
      style={{
        ...style,
        borderRadius: '10px',
        display: 'flex',
        top: 0,
        height: 0,
        justifyContent: 'space-between',
        width: 'calc(100% + 20px)',
        maxWidth: '100%',
        padding: '0px',
      }}
    >
      {item1 && (
        <RowItem
          key={`${item1?.Key}-${index * 2}`} // 고유한 key
          campaign={item1}
          index={index * 2}
          style={{
            ...style,
            ...RowStyle,
          }}
        />
      )}
      {item2 && (
        <RowItem
          key={`${item2?.Key}-${index * 2 + 1}`} // 고유한 key
          campaign={item2}
          index={index * 2 + 1}
          style={{
            ...style,
            ...RowStyle,
          }}
        />
      )}
    </div>
  );
};

/**RVCampaign */
const RVCampaign = ({ campaign }) => {
  const {
    state: { custom, currency },
  } = useCustomRecoilState();

  const { handleClickRV } = useClickCampaign();

  return (
    <div
      className="px-16 py-18 inline-flex justify-between w-full active:bg-zigzagGray-100"
      role="presentation"
      onClick={() => handleClickRV(campaign)}
    >
      <img className="rounded-[12px] w-52 h-52 min-w-52" alt="비디오 광고 이미지" src={RVIcon} />
      <div className="flex flex-col w-full gap-4 whitespace-nowrap overflow-hidden text-ellipsis px-12 justify-center">
        <div className="text-15 text-zigzagGray-900 font-bold truncate">
          영상 보고 포인트 적립하기
        </div>
        <div className="text-12 text-zigzagGray-500 truncate">
          동영상을 시청만 하면 포인트를 드려요
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div
          style={{
            backgroundColor: convertHexToRgba(custom?.primaryColor),
            color: custom?.primaryColor,
          }}
          className={`text-[14px] leading-[18.2px] rounded-[6px] px-[12px] py-[8px] break-keep min-w-[60px] flex justify-center items-center h-fit`}
        >
          <span>{`${campaign?.Point}${currency}`}</span>
        </div>
      </div>
    </div>
  );
};

/**Default Campaign */
const DefaultCampaign = ({ campaign }) => {
  const {
    state: { installedCampaignList, custom, currency = 'P' },
  } = useCustomRecoilState();

  const { handleClickCampaign } = useClickCampaign();

  return (
    <div
      className="px-16 py-18 flex justify-between w-full active:bg-zigzagGray-100 group"
      role="presentation"
      onClick={() => handleClickCampaign(campaign)}
    >
      <div className="flex w-full">
        {campaign?.IconImgURL || campaign?.IconUrl ? (
          <img
            className="rounded-[12px] w-52 h-52 min-w-52"
            alt={campaign?.Title}
            src={campaign?.IconImgURL || campaign?.IconUrl}
          />
        ) : (
          <div className="min-w-52 h-52 rounded-[12px] bg-gray-300 inline-flex" />
        )}
        <div className="flex flex-col w-full gap-4 whitespace-nowrap overflow-hidden text-ellipsis px-12 justify-center">
          <div className="text-15 text-zigzagGray-900 font-bold truncate">{campaign?.Title}</div>
          <div className="text-12 text-zigzagGray-500 truncate w-full">
            {campaign?.Desc || campaign?.Description || ''}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div
            style={{
              backgroundColor: convertHexToRgba(custom?.primaryColor),
              color: custom?.primaryColor,
            }}
            className={`max-w-[100px] text-[14px] leading-[18.2px] rounded-[6px] px-[12px] py-[8px] whitespace-pre flex justify-center items-center h-fit`}
          >
            {installedCampaignList?.includes(campaign.Key) ? (
              <span className="text-nowrap">설치 확인</span>
            ) : (
              <span>
                {campaign?.RewardDetail
                  ? `${numberWithCommas(campaign?.RewardDetail?.Quantity)} ${
                      campaign?.RewardDetail?.Unit
                    }`
                  : `${
                      typeof campaign?.Reward === 'string'
                        ? campaign?.Reward
                        : `${numberWithCommas(campaign?.Reward)} ${currency}`
                    }`}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
