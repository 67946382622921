import { useCustomRecoilState } from 'hooks';

export const MainTabSection = () => {
  const {
    state: {
      tabList,
      selectedTab: { selectedTabId },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  return (
    <div className="text-[16px] leading-[22.4px] border-b border-gray-100 font-400 break-keep overflow-x-scroll">
      <div className="flex px-[20px] gap-[20px] w-fit">
        {tabList?.map((tab) => (
          <div
            key={tab.TypeNo}
            role="presentation"
            onClick={() => {
              setSelectedTab((prev) => {
                return { ...prev, selectedTabId: tab.TypeNo };
              });
            }}
            className={`py-[10px] px-10 m-auto flex justify-center items-center whitespace-nowrap ${
              tab.TypeNo === selectedTabId
                ? 'text-gray-900 font-bold border-b-[2px] border-gray-900'
                : 'text-gray-500'
            }`}
          >
            {tab.Name}
          </div>
        ))}
      </div>
    </div>
  );
};
