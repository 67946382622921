import { useState } from 'react';

import check from 'assets/check.png';
import searchOrder from 'assets/search-order.png';
import { BottomSheet } from 'components';
import { useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

export const FilteringOptionSection = () => {
  const {
    state: { currency, totalReward, selectedFilterOption },
    setState: { setSelectedFilterOption },
  } = useCustomRecoilState();

  const [showOptionSheet, setShowOptionSheet] = useState(false);
  const options = [
    { label: '추천 순', value: 0 },
    { label: '최신 순', value: 1 },
    { label: `${currency} 높은 순`, value: 2 },
    { label: `${currency} 낮은 순`, value: 3 },
  ];

  return (
    <>
      <div className="flex text-gray-800 text-[13px] py-[12px] leading-[18.2px] justify-between items-start">
        <div className="flex gap-[2px] relative font-regular">
          총<span className="font-medium">{numberWithCommas(totalReward)}</span>
          {currency} 적립 가능
        </div>
        <button
          className="flex justify-center items-center"
          onClick={() => {
            setShowOptionSheet(true);
          }}
        >
          <span>{options?.find((el) => el.value === selectedFilterOption)?.label}</span>
          <img src={searchOrder} alt="필터" className="size-13" />
        </button>
      </div>
      {showOptionSheet && (
        <BottomSheet
          onClose={(e) => {
            if (e.target === e.currentTarget) {
              setShowOptionSheet(false);
            }
          }}
          content={
            <div className=" text-gray-500 p-20 text-[14px] leading-[19.6px] gap-14 flex flex-col items-center font-regular">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`w-full h-24 flex justify-between ${
                    selectedFilterOption === option.value && 'text-[#212529] font-medium'
                  }`}
                  onClick={() => {
                    setSelectedFilterOption(option.value);
                    setShowOptionSheet(false);
                  }}
                >
                  <span className="self-center">{option.label}</span>
                  {selectedFilterOption === option.value && (
                    <img src={check} alt="체크 아이콘" className="size-24" />
                  )}
                </button>
              ))}
            </div>
          }
        />
      )}
    </>
  );
};
