import { Suspense } from 'react';

import { ChoiceOfferPopup } from 'components';

import { CampaignList } from './campaign-list';
import { Header } from './header';
import { TabController } from './tab-controller';

export function CashNotePage() {
  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header />
      <div className="w-full h-full flex flex-col relative">
        <Suspense>
          <TabController>
            <Suspense>
              <CampaignList />
            </Suspense>
          </TabController>
        </Suspense>
        <ChoiceOfferPopup />
      </div>
    </div>
  );
}
