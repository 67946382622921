import { useState } from 'react';
import Slider from 'react-slick';

import add from 'assets/add.svg';
import BannerImg1 from 'assets/bridge-banner1.png';
import BannerImg2 from 'assets/bridge-banner2.png';
import BannerImg3 from 'assets/bridge-banner3.png';
import noAd from 'assets/no-ad.svg';
import { useClickCampaign, useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BottomSheet, DailyParticipation } from '.';

const ListTypeSetting = {
  dots: true,
  dotsClass: 'flex gap-4 justify-center', // Tailwind 클래스로 대체
  appendDots: (dots) => {
    return (
      <div className="flex justify-center items-center gap-4">
        {dots.map((item, index) => {
          const isActive = item.props.className === 'slick-active';

          if (isActive)
            return <div className="w-14 h-6 bg-bridgeColor-primary rounded-full" key={index} />;
          return <div className="h-6 w-6 bg-[#dfe2e7] rounded-full" key={index} />;
        })}
      </div>
    );
  },
  arrow: false,
  Infinite: false,
  className: 'pl-20',
  centerMode: false,
  variableWidth: true,
  slidesToScroll: 1,
  slidesPerRow: 1,
  slidesToShow: 1.04,
  responsive: [
    {
      breakpoint: 330,
      settings: {
        slidesToShow: 1.04,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 1.02,
      },
    },
  ],
  arrows: false,
};

const BannerTypeSetting = {
  dots: false,
  className: 'pl-20',
  arrow: false,
  centerMode: false,
  variableWidth: true,
  slidesToScroll: 2,
  slidesPerRow: 1,
  arrows: false,
};

const ImgMapper = {
  0: BannerImg1,
  1: BannerImg2,
  2: BannerImg3,
};

const PointIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="shrink-0"
    >
      <g mask="url(#mask0_1363_1023)">
        <path
          d="M7.35 12.3167C7.37222 12.6167 7.41944 12.9083 7.49167 13.1917C7.56389 13.475 7.66111 13.7444 7.78333 14C7.30556 13.9889 6.73611 13.9333 6.075 13.8333C5.41389 13.7333 4.78056 13.5778 4.175 13.3667C3.56944 13.1556 3.05556 12.8833 2.63333 12.55C2.21111 12.2167 2 11.8111 2 11.3333V9.66667C2 10.1 2.18611 10.4778 2.55833 10.8C2.93056 11.1222 3.38611 11.3889 3.925 11.6C4.46389 11.8111 5.04444 11.975 5.66667 12.0917C6.28889 12.2083 6.85 12.2833 7.35 12.3167ZM8.45 8.98333C8.23889 9.22778 8.05556 9.48889 7.9 9.76667C7.74444 10.0444 7.62222 10.3389 7.53333 10.65C7.03333 10.6278 6.46389 10.5611 5.825 10.45C5.18611 10.3389 4.58333 10.1778 4.01667 9.96667C3.45 9.75556 2.97222 9.48889 2.58333 9.16667C2.19444 8.84444 2 8.45555 2 8V6.33333C2 6.82222 2.22778 7.23611 2.68333 7.575C3.13889 7.91389 3.68333 8.18889 4.31667 8.4C4.95 8.61111 5.60833 8.76389 6.29167 8.85833C6.975 8.95278 7.54444 9 8 9C8.06667 9 8.13611 8.99722 8.20833 8.99167C8.28056 8.98611 8.36111 8.98333 8.45 8.98333ZM8 7.33333C6.33333 7.33333 4.91667 7.07222 3.75 6.55C2.58333 6.02778 2 5.4 2 4.66667C2 3.93333 2.58333 3.30556 3.75 2.78333C4.91667 2.26111 6.33333 2 8 2C9.66667 2 11.0833 2.26111 12.25 2.78333C13.4167 3.30556 14 3.93333 14 4.66667C14 5.4 13.4167 6.02778 12.25 6.55C11.0833 7.07222 9.66667 7.33333 8 7.33333ZM11.6667 14H12.3333V11.2667L13.5333 12.4667L14 12L12 10L10 12L10.4667 12.4667L11.6667 11.2667V14ZM12 15.3333C11.0778 15.3333 10.2917 15.0083 9.64167 14.3583C8.99167 13.7083 8.66667 12.9222 8.66667 12C8.66667 11.0778 8.99167 10.2917 9.64167 9.64167C10.2917 8.99167 11.0778 8.66667 12 8.66667C12.9222 8.66667 13.7083 8.99167 14.3583 9.64167C15.0083 10.2917 15.3333 11.0778 15.3333 12C15.3333 12.9222 15.0083 13.7083 14.3583 14.3583C13.7083 15.0083 12.9222 15.3333 12 15.3333Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export function CarouselSection() {
  const { handleClickCampaign } = useClickCampaign();

  const {
    state: { totalReward, bridgePageInfo, currency },
  } = useCustomRecoilState();

  const [selectedSort, setSelectedSort] = useState(null);

  const getResolvedURL = (url) => {
    if (!url) return '';
    return url.includes('http') ? url : `https://static.adbrix.igaworks.com/adpopcorn/${url}`;
  };

  const installedCampaignList = localStorage.getItem('installedCampaigns')
    ? JSON.parse(localStorage.getItem('installedCampaigns'))
    : [];

  if (!bridgePageInfo)
    return (
      <div>
        <div className="w-full h-12 bg-[#F7F7F7]" />
        <div className="pt-60 pb-80 flex flex-col gap-16 justify-center items-center w-full">
          <img src={noAd} alt="시계" className="w-64 h-64" />
          <div className=" flex-col justify-start items-center gap-2 inline-flex">
            <div className="text-bridgeColor-gray-400 text-bridgeFontSize-16 font-semibold">
              미션을 준비중이에요.
            </div>
            <div className="text-bridgeColor-gray-400 text-bridgeFontSize-14 font-normal">
              잠시 후 다시 확인해주세요!
            </div>
          </div>
        </div>
      </div>
    );

  if (totalReward === 0)
    return (
      <div>
        <div className="w-full h-12 bg-[#F7F7F7]" />
        <div className="pt-60 pb-80 flex flex-col gap-16 justify-center items-center w-full">
          <img src={noAd} alt="시계" className="w-64 h-64" />
          <div className=" flex-col justify-start items-center gap-2 inline-flex">
            <div className="text-bridgeColor-gray-400 text-bridgeFontSize-16 font-semibold">
              참여 가능한 미션을 모두 완료했어요.
            </div>
            <div className="text-bridgeColor-gray-400 text-bridgeFontSize-14 font-normal">
              우측 상단에 있는 리워드 받기 버튼을 클릭해주세요!
            </div>
          </div>
        </div>
      </div>
    );

  const { bridgeType, bridgeStyleType, bridgeSections } = bridgePageInfo;

  const selectedSectionInfo = bridgeSections?.find((el) => el.Sort === selectedSort);

  const PointTag = ({ point }) => {
    return (
      <div className="max-w-[100px] self-center">
        <div className="whitespace-pre h-32 px-12 py-6 bg-bridgeColor-primary rounded-full justify-center items-center text-white self-center text-bridgeFontSize-14 font-medium gap-4 flex">
          {point ? (
            <>
              <div className="truncate">{point}</div>
              <PointIcon className="shrink-0" />
            </>
          ) : (
            <div className="truncate">참여 완료</div>
          )}
        </div>
      </div>
    );
  };

  const BridgeSectionInfoBanner = ({ title, pointText, imgSrc, imgPosition }) => {
    return (
      <div
        className={`flex justify-between ${
          bridgeType === 0 ? 'h-[130px]' : 'h-[148px]'
        } pl-20 items-center relative`}
      >
        <div className="flex gap-4 flex-col">
          <div className="z-10">
            <span className="text-bridgeColor-gray-700 text-bridgeFontSize-18 font-semibold">
              {title}
              <br />
            </span>
            <span className="text-bridgeColor-primary text-bridgeFontSize-18 font-semibold">
              {pointText}
            </span>
          </div>
          <DailyParticipation />
        </div>
        <div
          className={`w-[132px] flex-col inline-flex ${
            bridgeType === 0 ? `absolute right-0 ${imgPosition}` : ''
          }`}
        >
          <img alt="배너이미지" src={imgSrc || ''} />
        </div>
      </div>
    );
  };

  const ListType = ({ campaignList }) => {
    if (campaignList.length === 1) {
      const campaign = campaignList.slice(0, 1)?.[0];

      return (
        <button className="p-20 w-full flex" onClick={() => handleClickCampaign(campaign)}>
          <div className="h-52 rounded-[12px] flex w-full gap-10">
            <img
              className="w-[52px] h-[52px] rounded-[13px] flex-shrink-0"
              src={campaign?.IconImgURL || ''}
              alt="캠페인 아이콘"
            />

            <div className="flex flex-col w-full text-start justify-center items-start gap-4 overflow-hidden">
              <div className="text-bridgeColor-gray-600 text-bridgeFontSize-14 font-semibold truncate w-full">
                {campaign?.FeedTitle}
              </div>
              <span className="text-bridgeColor-gray-500 text-bridgeFontSize-12 font-normal truncate w-full">
                {campaign?.FeedDesc}
              </span>
            </div>

            <PointTag
              point={
                installedCampaignList.includes(campaign?.Key)
                  ? null
                  : numberWithCommas(campaign?.RewardDetail?.Quantity || 0)
              }
            />
          </div>
        </button>
      );
    }
    return (
      <div className="w-full py-20 overflow-hidden relative bg-white">
        <Slider
          {...{ ...ListTypeSetting, rows: campaignList?.length < 4 ? campaignList.length : 4 }}
        >
          {campaignList.map((campaign, idx) => (
            <button className="px-10 flex" key={idx} onClick={() => handleClickCampaign(campaign)}>
              <div className="gap-10 h-52 rounded-[12px] mb-24 flex w-full justify-between">
                <img
                  className="w-[52px] h-[52px] rounded-[13px] flex-shrink-0"
                  src={campaign?.IconImgURL || ''}
                  alt="캠페인 아이콘"
                />

                <div className="flex flex-col text-start justify-center items-start gap-4 w-full overflow-hidden">
                  <div className="text-bridgeColor-gray-600 text-bridgeFontSize-14 font-semibold truncate w-full">
                    {campaign?.FeedTitle}
                  </div>
                  <span className="text-bridgeColor-gray-500 text-bridgeFontSize-12 font-normal truncate w-full">
                    {campaign?.FeedDesc}
                  </span>
                </div>

                <PointTag
                  point={
                    installedCampaignList.includes(campaign?.Key)
                      ? null
                      : numberWithCommas(campaign?.RewardDetail?.Quantity || 0)
                  }
                />
              </div>
            </button>
          ))}
        </Slider>
      </div>
    );
  };

  const BannerType = ({ campaignList, onClick }) => {
    if (campaignList.length === 1) {
      const campaign = campaignList.slice(0, 1)[0];

      return (
        <div className="relative rounded-[12px] bg-bridgeColor-gray50 px-20 pb-20 overflow-hidden">
          <button
            className="w-full h-full py-16 px-12 bg-gray-50 rounded-xl flex-col justify-between items-center gap-16 inline-flex"
            onClick={() => handleClickCampaign(campaign)}
          >
            <div className="flex-col justify-center items-center gap-12 w-full flex">
              <img
                className="w-48 h-48 rounded-[12px] justify-center items-center inline-flex"
                src={campaign?.IconImgURL || ''}
                alt="캠페인 아이콘"
              />

              <div className="flex-col text-start justify-start items-center gap-4 flex w-full overflow-hidden">
                <div className="text-center text-bridgeColor-gray-600 text-bridgeFontSize-14 font-semibold line-clamp-2 w-full text-ellipsis">
                  {campaign?.FeedTitle}
                </div>
                <div className="text-center text-bridgeColor-gray-500 text-bridgeFontSize-12 font-medium line-clamp-1 w-full text-ellipsis">
                  {campaign?.FeedDesc}
                </div>
              </div>
            </div>

            <PointTag
              point={
                installedCampaignList.includes(campaign?.Key)
                  ? null
                  : numberWithCommas(campaign?.RewardDetail?.Quantity || 0)
              }
            />
          </button>
        </div>
      );
    }
    return (
      <div className="w-full flex flex-col overflow-hidden">
        <Slider {...BannerTypeSetting}>
          {campaignList?.map((campaign, idx) => (
            <div key={idx} className="px-5">
              <button
                onClick={() => handleClickCampaign(campaign)}
                className="rounded-[12px] bg-bridgeColor-gray-50 mix-w-[130px] max-w-[164px] h-[200px] px-12 py-16  flex-col justify-between items-center gap-16 inline-flex"
              >
                <div className="flex-col justify-center items-center gap-12 flex w-full">
                  <img
                    className="w-48 h-48 rounded-xl justify-center items-center inline-flex"
                    src={campaign?.IconImgURL || ''}
                    alt="캠페인 아이콘"
                  />

                  <div className="flex-col justify-start items-center text-start gap-4 flex overflow-hidden w-full">
                    <div className="text-center text-bridgeColor-gray-600 text-bridgeFontSize-14 font-semibold text-ellipsis w-full line-clamp-2">
                      {campaign?.FeedTitle}
                    </div>
                    <div className="text-center text-bridgeColor-gray-500 text-bridgeFontSize-12 font-medium text-ellipsis w-full line-clamp-1">
                      {campaign?.FeedDesc}
                    </div>
                  </div>
                </div>

                <PointTag
                  point={
                    installedCampaignList.includes(campaign?.Key)
                      ? null
                      : numberWithCommas(campaign?.RewardDetail?.Quantity || 0)
                  }
                />
              </button>
            </div>
          ))}
        </Slider>
        <button
          onClick={onClick}
          className="self-center w-fit pl-12 mt-20 pr-8 py-8 bg-gray-50 rounded-[10px] mb-32 justify-center items-center gap-2 inline-flex"
        >
          <div className="text-bridgeColor-gray-500 text-bridgeFontSize-14 font-medium">
            전체보기
          </div>
          <img src={add} alt="더하기 아이콘" />
        </button>
      </div>
    );
  };

  return (
    <div>
      {bridgeSections?.map((bridge, idx) =>
        bridge.Campaigns?.length ? (
          <div key={idx}>
            <div className="w-full h-12 bg-[#F7F7F7]" />
            <div className="overflow-hidden">
              <BridgeSectionInfoBanner
                imgSrc={
                  bridgeType === 1 ? getResolvedURL(bridge?.IconImgURL) : ImgMapper[bridge.Sort]
                }
                title={
                  bridge.Sort === 0 && bridgeType === 0
                    ? `${numberWithCommas(
                        totalReward ? Math.ceil(totalReward / bridgeSections?.length) : 0
                      )}${currency} 받을 수 있는`
                    : bridge.Title
                }
                pointText={bridge.PointTitle}
                desc={bridge.Description}
              />
              {bridgeStyleType === 0 ? (
                <ListType campaignList={bridge.Campaigns?.slice(0, 16)} />
              ) : (
                <BannerType
                  campaignList={bridge.Campaigns?.slice(0, 10)}
                  onClick={() => {
                    setSelectedSort(bridge?.Sort);
                  }}
                />
              )}
            </div>
          </div>
        ) : null
      )}

      <BottomSheet
        isOpen={selectedSectionInfo}
        onClose={() => setSelectedSort(null)}
        content={
          <div className="max-h-[calc(100vh-212px)] overscroll-none">
            <BridgeSectionInfoBanner
              imgPosition="bottom-0"
              imgSrc={
                bridgeType === 1
                  ? getResolvedURL(selectedSectionInfo?.IconImgURL)
                  : ImgMapper[selectedSort]
              }
              title={
                selectedSort === 0 && bridgeType === 0
                  ? `${numberWithCommas(
                      totalReward ? Math.ceil(totalReward / bridgeSections?.length) : 0
                    )}${currency} 받을 수 있는`
                  : selectedSectionInfo?.Title
              }
              pointText={selectedSectionInfo?.PointTitle}
            />
            <div className="overflow-scroll max-h-[calc(100vh-358px)]">
              <div className="flex flex-col gap-16 m-auto px-20 py-10">
                {selectedSectionInfo?.Campaigns?.map((campaign, idx) => {
                  return (
                    <div
                      role="presentation"
                      onClick={() => {
                        setSelectedSort(null);
                        handleClickCampaign(campaign);
                      }}
                      className="rounded-[12px] items-center border border-bridgeColor-gray-200 h-72 w-full flex p-10 gap-10"
                      key={idx}
                    >
                      <img
                        alt="캠페인 아이콘"
                        className="w-40 h-40 rounded-[10px]"
                        src={campaign?.IconImgURL || ''}
                      />
                      <div className="flex-col justify-start items-start text-start gap-2 flex w-full overflow-hidden">
                        <div className="text-bridgeColor-gray-600 text-bridgeFontSize-14 font-semibold truncate w-full">
                          {campaign?.FeedTitle}
                        </div>
                        <span className="text-bridgeColor-gray-500 text-bridgeFontSize-12 font-normal truncate w-full">
                          {campaign?.FeedDesc}
                        </span>
                      </div>

                      <PointTag
                        point={
                          installedCampaignList.includes(campaign?.Key)
                            ? null
                            : numberWithCommas(campaign?.RewardDetail?.Quantity || 0)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default CarouselSection;
