import { useCustomRecoilState } from 'hooks';

export const SubTabSection = () => {
  const {
    state: {
      campaignList,
      selectedTab: { selectedTabId, selectedSubTabData },
    },
    setState: { setSelectedTab },
  } = useCustomRecoilState();

  const selectedSubTabInfo =
    campaignList?.find((tab) => tab.TypeNo === selectedTabId)?.SubTabInfo || [];

  if (selectedSubTabInfo?.length === 0) return null;
  return (
    <div className="flex gap-8 px-20 py-10 text-[13px] whitespace-nowrap w-full overflow-x-auto">
      {selectedSubTabInfo?.map((subTab) => (
        <div
          className={`py-6 px-12 rounded-[16px] ${
            subTab.TypeNo === selectedSubTabData[selectedTabId]
              ? 'text-white bg-[#222B3E] font-semibold'
              : 'text-[#0C1120] border border-[#E8EDF2] font-semibold bg-white'
          }`}
          key={subTab.TypeNo}
          role="presentation"
          onClick={() => {
            setSelectedTab((prev) => {
              return {
                ...prev,
                selectedSubTabData: {
                  ...prev.selectedSubTabData,
                  [prev.selectedTabId]: subTab.TypeNo,
                },
              };
            });
          }}
        >
          {subTab.Name?.replace(/#/g, '')}
        </div>
      ))}
    </div>
  );
};
