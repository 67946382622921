import { useState } from 'react';

import arrowDown from 'assets/arrow-down.png';
import arrowUp from 'assets/arrow-up.png';
import { ReactComponent as Check } from 'assets/check.svg';
import clock from 'assets/clock.png';
import { useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

export const MultiRewardInfo = ({ Campaign }) => {
  const {
    state: { custom, currency },
  } = useCustomRecoilState();
  const [showAllSubCampaigns, setShowAllSubCampaigns] = useState(false);
  return (
    <>
      {Campaign?.SubCampaigns && (
        <div className="p-[20px] text-[14px] flex flex-col gap-[24px] border border-gray-50 rounded-[16px]">
          {[
            ...(showAllSubCampaigns ? Campaign.SubCampaigns : Campaign.SubCampaigns.slice(0, 3)),
          ].map((sub) => (
            <div className="flex justify-between" key={sub.Description}>
              <div className="flex gap-[8px]">
                <Check stroke={sub.Complete ? '#D5D9DD' : custom.primaryColor} />
                <div className={`${sub.Complete ? 'text-gray-400' : 'text-gray-800'}`}>
                  {sub.Description}
                </div>
              </div>
              <div
                style={{ color: !sub.Complete && custom.primaryColor }}
                className={`${sub.Complete ? 'text-gray-400' : `font-[500]`}`}
              >
                {sub.Complete
                  ? '완료'
                  : sub?.RewardDetail?.Quantity
                  ? `${numberWithCommas(sub?.RewardDetail?.Quantity)} ${currency}`
                  : typeof sub?.Reward === 'string'
                  ? `${numberWithCommas(sub?.Reward)}`
                  : `${sub?.Reward || 0} ${currency}`}
              </div>
            </div>
          ))}
          <div className="border-t border-gray-50 h-[36px] flex justify-center items-end">
            <div
              className="flex justify-center items-center"
              role="presentation"
              onClick={() => setShowAllSubCampaigns(!showAllSubCampaigns)}
            >
              <div className="text-gray-800">
                {Campaign?.SubCampaigns?.filter((sub) => sub.Complete).length}&nbsp;
              </div>
              <div className="text-gray-400">
                &nbsp;/&nbsp;{Campaign?.SubCampaigns?.length}&nbsp;
              </div>
              <div>참여 완료</div>
              <img
                src={showAllSubCampaigns ? arrowUp : arrowDown}
                alt="icon"
                className="w-[16px] h-[16px]"
              />
            </div>
          </div>
        </div>
      )}
      {Campaign?.MultiRewardDurationDescription && (
        <div
          style={{
            color: custom.primaryColor,
          }}
          className={`bg-[#F0F7FE] rounded-full flex gap-[8px] justify-center items-center py-[16px] px-[20px] text-[14px] leading-[20px] font-500`}
        >
          <img src={clock} alt="clock" />
          <div>{Campaign?.MultiRewardDurationDescription}</div>
        </div>
      )}
      {Campaign?.DetailRewardConditionMessageList && (
        <div className="flex flex-col gap-10 p-20 rounded-[12px] bg-zigzagGray-100 mb-20">
          <ul className="list-inside list-disc text-zigzagGray-500 font-[400] tracking-[-0.4px] leading-[18.2px] text-[13px] flex flex-col gap-[2px] justify-start text-start relative">
            {Campaign.DetailRewardConditionMessageList?.map((message) => {
              return <li key={message}>{message}</li>;
            })}
          </ul>
        </div>
      )}
    </>
  );
};
