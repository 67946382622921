import { useCustomRecoilState, useSDKInterface } from 'hooks';
import { convertCampaignParameter } from 'utils';

import {
  CampaignJoinButton,
  CampaignOverview,
  DetailRewardConditionMessageList,
  Divider,
  Header,
  MultiRewardInfo,
  Notification,
  ParticipationStepMessageList,
  useDetailPage,
} from './default';

export function DefaultPage() {
  useDetailPage();
  const {
    state: { selectedCampaign },
  } = useCustomRecoilState();

  const { joinCampaign } = useSDKInterface();

  if (!selectedCampaign) return null;

  return (
    <div className="flex flex-col overflow-hidden h-[100vh]">
      <Header title={selectedCampaign.FeedTitle} />

      <div className="overflow-scroll">
        <CampaignOverview Campaign={selectedCampaign} />
        <MultiRewardInfo />
        <DetailRewardConditionMessageList />

        <Divider />
        <ParticipationStepMessageList
          participationStepMessageList={selectedCampaign?.ParticipationStepMessageList}
        />

        <Divider />
        <Notification />

        <CampaignJoinButton
          onConfirm={() => {
            joinCampaign([`"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`]);
          }}
        />
      </div>
    </div>
  );
}
