import { atom, selector } from 'recoil';

import { MAIN_TAB_TYPES } from 'constants/index';

// Recoil을 사용하여 전역적으로 상태를 관리하는 상태
export const customState = atom({
  key: 'customState2',
  default: {
    offerwallTitle: '리워드 충전소',
    primaryColor: '#2685F4', // 기본값은 Tailwind CSS의 기본 primary 색상
    startTabIndex: 0,
  },
});

export const isNewInAppBrowserState = atom({ key: 'isNewInAppBrowser2', default: false });

export const cashNoteState = atom({ key: 'cashNoteState2', default: false });

export const isViewModeState = atom({ key: 'isViewModeState2', default: false });

export const isLandscapeState = atom({ key: 'isLandscapeState2', default: false });

export const completedRcpcListState = atom({ key: 'completetedRcpcList2', default: [] });

export const NoAdModalState = atom({ key: 'noAd2', default: false });

export const is501ErrorState = atom({ key: 'is501Error2', default: false });

export const platformState = atom({
  key: 'platformState2',
  default: null,
});

export const RVFloatingState = atom({
  key: 'RVFloatingState2',
  default: false,
});

export const RVInfoListState = atom({
  key: 'RVInfoList2',
  default: [],
});

export const mediaKeyState = atom({
  key: 'mediaKey2',
  default: null,
});

export const parameterState = atom({
  key: 'parameter2',
  default: null,
});

export const selectedCampaignState = atom({
  key: 'selectedCampaign2',
  default: null,
});

export const csState = atom({
  key: 'csPageData2',
  default: null,
});

export const cpmCampaignListState = atom({
  key: 'cpmCampaignListState',
  default: [],
});

/**Campaign List  */
export const isTestState = atom({
  key: 'isTestValue2',
  default: false,
});

export const totalRewardState = atom({
  key: 'totalReward2',
  default: 0,
});

export const totalOfferwallRewardQuantityState = atom({
  key: 'totalOfferwallRewardQuantityState',
  default: 0,
});

export const currencyState = atom({
  key: 'currencyValue2',
  default: 'P',
});

export const choiceOfferCampaignState = atom({
  key: 'choiceOfferCampaignValue2',
  default: null,
});

export const campaignListState = atom({
  key: 'campaignListValue2',
  default: [],
});

export const selectedTabCampaignState = selector({
  key: 'selectedTabCampaignState2',
  set: () => {},
  get: ({ get }) => {
    const selectedTabInfo = get(selectedTabState);
    const { selectedTabId, selectedSubTabData } = selectedTabInfo;
    const campaignList = get(campaignListState);
    const rvInfoList = get(RVInfoListState);
    const blockedCampaigns = get(blockedCampaignListState);
    const temporalBlockedCampaigns = get(temporalBlockedCampaignListValue);
    const cpmCampaignList = get(cpmCampaignListState);
    const selectedOption = get(selectedFilterOptionState);

    const selectedTabCampaignInfo = campaignList?.find((tab) => tab.TypeNo === selectedTabId);

    const selectedTabCampaigns =
      selectedTabId === MAIN_TAB_TYPES['간편적립']
        ? cpmCampaignList
        : selectedTabCampaignInfo?.campaigns ||
          selectedTabCampaignInfo?.SubTabInfo?.find(
            (subTab) => subTab.TypeNo === selectedSubTabData[selectedTabId]
          )?.campaigns ||
          [];

    const rvListAd = rvInfoList?.find((rv) => rv.PlacementTypeNo === 0);

    let filteredSelectedCampaigns = (selectedTabCampaigns || [])
      ?.filter((el) => !Object?.keys(blockedCampaigns).includes(el?.Id?.toString()))
      ?.filter(
        (el) =>
          !temporalBlockedCampaigns.some((blockedCampaign) => {
            return (
              blockedCampaign.key ===
              (blockedCampaign.type === 'campaign' ? el?.Key?.toString() : el?.Id?.toString())
            );
          })
      );

    filteredSelectedCampaigns = filteredSelectedCampaigns.sort((a, b) => {
      const aValue = a?.RewardDetail?.Quantity || a.Reward || 0;
      const bValue = b?.RewardDetail?.Quantity || b.Reward || 0;

      if (selectedOption === 2) {
        return bValue - aValue; // 내림차순 정렬
      } else if (selectedOption === 3) {
        return aValue - bValue; // 오름차순 정렬
      } else if (selectedOption === 1) {
        return b?.RegistUnixTime - a?.RegistUnixTime;
      } else {
        return 0; // 추천순, 정렬하지 않음
      }
    });

    if (rvListAd && rvListAd?.IsAvailable) {
      const index = selectedTabId === MAIN_TAB_TYPES['참여적립'] ? rvListAd.DisplayPriority - 1 : 0;
      filteredSelectedCampaigns?.splice(index, 0, rvListAd);
    }

    return filteredSelectedCampaigns;
  },
});

/** Tab List */
export const tabListState = atom({
  key: 'tabListState2',
  default: [],
});

/** Selected Tab */
export const selectedTabState = atom({
  key: 'selectedTabValue2',
  default: {
    selectedTabId: 0,
    selectedSubTabData: {},
  },
});

export const temporalBlockedCampaignListValue = atom({
  key: 'temporalBlockedCampaignListValue2',
  default: [],
});

export const blockedCampaignListState = atom({
  key: 'blockedCampaignListState2',
  default: [],
});

export const installedCampaignListState = atom({
  key: 'installedCampaignListState',
  default: [],
});

export const selectedFilterOptionState = atom({
  key: 'selectedFilterOptionState',
  default: 0,
});

export const bridgePageInfoState = atom({
  key: 'BridgePageInfoState',
  default: null,
});
