import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowBack from 'assets/arrow_back.svg';
import { useCustomRecoilState } from 'hooks';

import { CSFAQ } from './cs-faq';
import { CSHistory } from './cs-history';
import { CSRequest } from './cs-request';
import { useCSPage } from './use-cs-page';

export const CSPage = () => {
  useCSPage();
  const navigate = useNavigate();

  const {
    state: { isNoAd },
  } = useCustomRecoilState();

  const [selectedTab, setSelectedTab] = useState(isNoAd ? 1 : 0);
  const tabList = ['적립내역', '자주 묻는 질문', '문의하기'];

  return (
    <>
      <div className="w-full h-[100vh] bg-white z-10 flex flex-col absolute top-0 overflow-hidden left-0">
        <div
          className="h-[44px] flex gap-[6px] px-[15px] py-[10px] justify-left items-center w-fit"
          role="presentation"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
          <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">
            고객센터
          </div>
        </div>
        <div className="px-[15px] grid grid-cols-3 gap-[22px] text-[16px] leading-[22.4px] border-b border-gray-100 font-400">
          {tabList.map((tab, idx) => (
            <div
              key={idx}
              role="presentation"
              onClick={() => {
                setSelectedTab(idx);
              }}
              className={`py-[10px] flex items-center justify-center ${
                idx === selectedTab
                  ? 'text-gray-900 font-bold border-b-[2px] border-gray-900'
                  : 'text-gray-500'
              }`}
            >
              {tab}
            </div>
          ))}
        </div>

        <div
          className={`flex flex-col bg-white h-full ${
            selectedTab === 0 ? 'overflow-hidden' : 'overflow-scroll'
          }`}
        >
          {selectedTab === 0 && <CSHistory />}
          {selectedTab === 1 && <CSFAQ />}
          {selectedTab === 2 && <CSRequest />}
        </div>
      </div>
    </>
  );
};

export default CSPage;
