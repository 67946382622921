// import { RVAd } from 'components/RV-ad';
import { useLocation } from 'react-router-dom';

const mockData = {
  app_key: 2075501244,
  usn: 'izzie-test',
  adid: '77230152-17c2-422a-8901-5a309834387f',
  network: 'wifi',
  carrier: 'unknown',
  model: 'SM-G970N',
  manufacturer: 'samsung',
  os_version: '12',
  sdk_version: '9.0.0a',
  width: 1080,
  height: 2038,
  country: 'KR',
  language: 'ko',
  use_google_account: false,
  google_account_list: [],
  has_one_store: true,
  one_store_account_list: [],
  utc_offset: 9,
  market: '',
  android_opt_out_enabled: false,
  platform: 'android',
  sign_1: false,
  sign_2: false,
  sign_3: false,
  timestamp: 1715153450,
  build_target_version: 31,
};

export const useMockData = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isLocalhost = queryParams.get('env') === 'localhost';

  // LocalStorage에서 객체를 불러오기

  if (!isLocalhost) return null;
  if (window.location.hostname !== 'localhost') return null;

  return mockData;
};
