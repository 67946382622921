import { useCustomRecoilState } from 'hooks';
import { numberWithCommas } from 'utils';

export const CampaignOverview = ({ campaign }) => {
  const {
    state: { custom, currency },
  } = useCustomRecoilState();
  const { FeedMainImgURL, FeedDetailImgURL, IconImgURL } = campaign;

  const imgSrc = FeedDetailImgURL || FeedMainImgURL;

  if (imgSrc) {
    return (
      <>
        <img className="rounded-[16px]" alt="FeedImg" height="auto" src={imgSrc} key={imgSrc} />

        {campaign?.MultiRewardConditionDescription && (
          <div
            style={{
              color: custom.primaryColor,
            }}
            className={`text-[12px] leading-[16px] py-[6px] px-[12px] border border-[#D8E9FD] rounded-[999px] w-fit`}
          >
            {campaign?.MultiRewardConditionDescription}
          </div>
        )}

        <div className="text-20 font-bold py-30 text-zigzagGray-800 whitespace-pre-wrap text-center">
          {campaign.FeedTitle}
          <br />
          {campaign.FeedDesc}
          <span className="font-semibold text-zigzagPink-400"> {campaign.Reward}</span>
          <span> 지급!</span>
        </div>
      </>
    );
  }

  return (
    <div className="flex items-center gap-10">
      <img
        style={{ borderStyle: 'none' }}
        alt="iconImg"
        width="60px"
        height="60px"
        src={IconImgURL}
        key={IconImgURL}
      />

      <div className="text-20 font-bold text-zigzagGray-800 whitespace-pre-wrap text-start">
        {campaign.FeedTitle}
        <br />
        {campaign.FeedDesc}
        <span className="font-semibold text-zigzagPink-400">
          {campaign?.RewardDetail
            ? `${numberWithCommas(campaign?.RewardDetail?.Quantity)} ${
                campaign?.RewardDetail?.Unit
              }`
            : `${
                typeof campaign?.Reward === 'string'
                  ? campaign?.Reward
                  : `${numberWithCommas(campaign?.Reward)} ${currency}`
              }`}
        </span>
        <span> 지급!</span>
      </div>
    </div>
  );
};
