import { useEffect } from 'react';

import { getCampaignInfo } from 'apis';
import { useErrorModal } from 'context';
import { useCustomRecoilState, useQueryParams, useSDKInterface } from 'hooks';

export function useDetailPage() {
  const { openModal } = useErrorModal();
  const { isTestMode } = useSDKInterface();
  const {
    state: { selectedCampaign, isTest },
    setState: { setSelectedCampaign },
  } = useCustomRecoilState();

  const { auth } = useQueryParams();

  useEffect(() => {
    async function fetch() {
      try {
        const campaignListData = await getCampaignInfo(auth, isTest);
        setSelectedCampaign(campaignListData?.Campaign || null);
      } catch (err) {
        openModal(err.message || '');
      }
    }

    if (selectedCampaign) return;
    if (!auth) return;

    isTestMode([isTest]);
    fetch();
  }, [auth, selectedCampaign]);
}
