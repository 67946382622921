import { getTabInfo } from 'apis';
import { useErrorModal } from 'context';
import { useCustomRecoilState } from 'hooks';

export const useFetchTab = () => {
  const { openModal } = useErrorModal();
  const {
    state: { custom, mediaKey, parameter },
    setState: { setTabList, setSelectedTab },
  } = useCustomRecoilState();

  const fetchTabInfo = async () => {
    if (!mediaKey || !parameter) return;

    try {
      const tabInfoData = await getTabInfo(mediaKey, parameter);
      const tabInfo = tabInfoData?.MediaTabInfo || [];

      const startTabInfo = tabInfoData?.StartTabInfo;
      const MainTabStartTabIndex = custom.startTabIndex;

      const MainTabTypeNo = startTabInfo?.MainTabTypeNo
        ? startTabInfo?.MainTabTypeNo
        : tabInfo[MainTabStartTabIndex].TypeNo;

      const SubTabTypeNo = startTabInfo?.MainTabTypeNo ? startTabInfo?.SubTabTypeNo : null;

      const initialState = tabInfo?.reduce(
        (acc, cur) => {
          if (cur.TypeNo === MainTabTypeNo) {
            acc.selectedTabId = cur.TypeNo;
            acc.selectedSubTabData[cur.TypeNo] = SubTabTypeNo
              ? SubTabTypeNo
              : cur.SubTabInfo?.length
              ? cur.SubTabInfo.find((subTab) => subTab.IncludeParticipationNo.includes('0')).TypeNo
              : null;
            return acc;
          }

          acc.selectedSubTabData[cur.TypeNo] = cur.SubTabInfo?.length
            ? cur.SubTabInfo.find((subTab) => subTab.IncludeParticipationNo.includes('0')).TypeNo
            : null;

          return acc;
        },
        { selectedTabId: 0, selectedSubTabData: {} }
      );

      if (initialState) {
        setTabList(tabInfo);
        setSelectedTab(initialState);
      }
    } catch (err) {
      openModal(err.message || '');
    }
  };

  return fetchTabInfo;
};
